import clsx from "clsx";
import Status from "components/Status";
import Table from "components/Table";
import SearchField from "components/shared/SearchField";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SalesOpsService } from "services/salesops.service";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";


export default function WalletFunding () {
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const page = searchParams.get("page") ?? 1
    let params = useParams()

    useEffect(()=>{
        setIsLoading(true)
        SalesOpsService.getBusinessFunding(params?.id, {
            page,
            search
        })
            .then(response => response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setData(res?.data)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search, page])

    return (
        <div>
            <SearchField 
                containerClass='ml-auto my-6'
                placeholder="Search by type"
            />
            <Table
                loading={isLoading}
                data={data?.results||[]}
                backendPaginated={true}
                totalPages={data?.totalPages ?? 0}
                theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                tdataClass="py-6 pl-[10px] text-sm"
                trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                hideTableTop={true}
                hideOverallTableBorder={true}
                hideTableBodyBorder={true}
                hideLines={true}
                paginationContainer="!px-0"
                rows={[
                    {
                        header:'ID',
                        view: (item) => (
                            <div className='!w-[150px] text-ellipsis overflow-hidden'>
                                <span>
                                    #{item?.id||"---"}
                                </span>
                            </div>
                        ),
                    },
                    {
                        header:'Type',
                        view: (item) => (
                            <Status
                                hideDot={true}
                                text={item?.type}
                                type={item?.type === 'Debit' ? "unverified" : 'verified'}
                                addClass="!text-sm py-1.5 px-4"
                            />
                        ),
                    },
                    {
                        header:'Payment Source',
                        view: (item) => (
                            <span>
                                {item?.debitAction||item?.paymentSource||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Date',
                        view: (item) => (
                            <span>
                                {dayjs(item?.created_at).format("DD/MM/YYYY hh:mm A")}
                            </span>
                        ),
                    },
                    {
                        header:'Amount',
                        view: (item) => (
                            <span
                                className={clsx(
                                    item?.type === 'Debit' ? "text-[#FF69B4]":"text-[#03A89E]"
                                )}
                            >
                                {currencyFormatter(item?.debitAmount||item?.amountPaid||0)}
                            </span>
                        ),
                    },
                ]}
            />
        </div>
    )
} 