import filter_lines from "images/svg/filter_lines.svg";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";


export default function Filter ({
    options, 
    keyName=null,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showOptions, setShowOptions] = useState(false)
    
    const changeFilter = (value) => {
        searchParams.set(keyName||'filter', value);
        setSearchParams(searchParams);
    }

    return (
        <div className="relative">
            <div className="border-[1px] border-[#D0D5DD] py-1.5 px-2.5 cursor-pointer rounded flex items-center gap-x-2.5" onClick={()=>setShowOptions(!showOptions)}>
                <img src={filter_lines} alt="" className="w-[20px]" />
                <p className="whitespace-nowrap">{searchParams.get(keyName||"filter") || "Filter"}</p>
            </div>
            {
                showOptions && 
                <div className="absolute p-2.5 shadow-md flex flex-col gap-1.5 bg-white z-10">
                    {
                        options.map((item, idx)=>(
                            <p className="whitespace-nowrap cursor-pointer" key={idx} onClick={()=>changeFilter(item.value)}>{item.label}</p>
                        ))
                    }
                </div>
            }
        </div>
    )
}