import htmlToJson from 'html-to-json-parser';
import { toast } from 'react-toastify';

export const parseHtmlToJson = () => {
    const html = '<div><h1>Hello World</h1></div>';
    const json = htmlToJson.parse(html);
    return json;
};

export const htmlConverter = (htmlString) => {

    fetch('https://html2json-api.herokuapp.com/api/v1/convert', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ html: htmlString })
    })
      .then(response => response.json())
      .then(data => {return data})
      .catch(error => console.error(error));
}

export const converter = (dom) => {
    if (dom.nodeType === Node.TEXT_NODE) {
        return dom.nodeValue;
    }
    if (dom.nodeType === Node.DOCUMENT_NODE) {
        dom = dom.documentElement;
    }
    const obj = {};
    obj.nodeType = dom.nodeType;
    if (dom.nodeType === Node.ELEMENT_NODE) {
        obj.tagName = dom.tagName;
        obj.attributes = []; // Array.from(obj.attributes) gives us a lot of things we don't want
        for (let i = 0, len = dom.attributes.length; i < len; ++i) {
            const attr = dom.attributes[i];
            obj.attributes.push({name: attr.name, value: attr.value});
        }
        obj.children = [];
        for (let child = dom.firstChild; child; child = child.nextSibling) {
            obj.children.push(converter(child));
        }
    } else {
        obj.nodeValue = dom.nodeValue;
    }
    return obj;
}

export const dateFormatter = (
  date,
  withTime=false,
) => {
  let result = ''; 
  const d = new Date(date).getDate();
  const m = new Date(date).getMonth();
  const y = new Date(date).getFullYear(); 

  // time
  let time = new Date(date);
  var hours = time?.getHours();
  var minutes = time?.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ampm;

  // 29/01/2023
  result = `${d<10 ? '0':""}${d}/${m<9 ? '0':""}${m+1}/${y}${withTime ? `, ${strTime}`:""}`
  return result;
}

export const handleRequestErrors = (err, noMessageToast=false) => {
    if(err.response){
        !noMessageToast && toast.error(err.response.data.message);
    } else{
        if(err.message === "timeout of 15000ms exceeded"){
            toast.error('Request timed out, please try again!')
        } else if (err.message==="Network Error"){
            toast.error('Network error, please make sure you are connected to the internet!')
        } else{
            toast.error("Your request can't be processed at this time, please try again later!");
        }
    }
}

export const currencyFormatter = (
    amount, 
    currency="NGN", 
    maxDigits=2,
    compact=false,
) => {
    var formatMoney = new Intl.NumberFormat(
        currency==="USD" ? 'en-US':'en-NG', 
        {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: maxDigits, 
            compactDisplay: "short",
            notation: compact ? "compact" : undefined,
        }
    );
    return formatMoney.format(isNaN(amount)||amount===Infinity ? 0:amount)
}

export const formatBigNumber = (num, withCommas=false) => {
    if(!withCommas){
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            return num.toString();
        }
    } else {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
