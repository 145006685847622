import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import { metricsStats } from '../../../lib/auth';
import Box from '../../Box';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../Form/SelectInput';
import Loader from '../../shared/Loader';

const Metrics = () => {
  const [query, setQuery] = useState('this_month')
  const [queryVal, setQueryVal] = useState('This month')
  // const [searchTerm, setSearchTerm] = useState('');
  // const [filteredData, setFilteredData] = useState([]);
  const [startDateee, setStartDateee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDatee] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

const handleClose = () => setShow(false);

    const [boxData, setBoxData] = useState({
        boxFor: "metrics",
        item: []
      });

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}
      
useEffect(() => {
  setIsLoading(true)
  setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
  setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
  metricsStats(query)
  .then(response => response.data)
  .then((res) => {
    if(res.status === 'success'){
      setBoxData({
        ...boxData,
        item: res.data
      })
      setIsLoading(false)
    }    else {
      toast.error("Something went wrong, please try again!!!")
      setIsLoading(false)
  }
})
.catch((err) => {
  if(err.response){
      toast.error(err.response.data.message);
      setIsLoading(false)
  } else{
      toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      setIsLoading(false)
  }
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [query])

const handleSelectChange = (name, val) => {
  if
  // (val === 'All'){
  //   setQuery('all')
  // }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
    setQueryVal('Last 7 days')
  }else if(val === 'This Month'){
    setQuery('this_month')
    setQueryVal('This Month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
    setQueryVal('Last Month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
    setQueryVal('Last 3 Months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
    setQueryVal('Last 6 Months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
    setQueryVal('Last 12 Months')
  }else if(val === 'Custom'){
    setQuery('custom')
    setQueryVal('Custom')
    setShow(true)
  }
}


  return (
     <div className='home_main'>

    <Modal show={show} onHide={handleClose}>
        <DateRange
            editableDateInputs={true}
            onChange={item => {
                    setStartDateee(formatPickerDate(item.selection.startDate))
                    setEndDateee(formatPickerDate(item.selection.endDate))
                    setState([item.selection])
                }
            }
            moveRangeOnFirstSelection={false}
            ranges={state}
        />
        <button 
            style={{
                color:"white",
                padding:"7px 25px",
                textAlign:"center",
                border:"1px solid #0046E6",
                background:"#0046E6"
            }} 
            onClick={()=>{   
                    handleClose();
                    setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                    setQueryVal(`${customStartDate} - ${customEndDate}`)
                }}
            >Proceed</button>
    </Modal>

    <ToastContainer />

        <div className='w-[150px] float-right -mt-[70px]'> 
            <SelectInput 
              placeholder={"This Month"}
              options={["Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
            />
        </div>
        {
          isLoading ?
          <Loader /> :
          <Box boxData={boxData} query={queryVal} /> 
        }

    


    </div>
  )
}

export default Metrics