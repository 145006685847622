import Status from "components/Status";
import Table from "components/Table";
import Button from "components/shared/Button";
import SearchField from "components/shared/SearchField";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FloatService } from "services/float.service";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";

export default function FloatRequests () {
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const navigate = useNavigate()
    let params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        if(!!search){
            const filtered = data?.filter((item) => item?._id?.toLowerCase()?.includes(search.toLowerCase())) 
            setTableData(filtered)
        } else {
            setTableData(data||[])
        }
    }, [data, search])

    useEffect(()=>{
        setIsLoading(true)
        FloatService.repaymentHistoryByBusinessId(params?.id)
            .then(response => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setData(res.data||[])
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <SearchField 
                containerClass='ml-auto my-6'
                placeholder="Search by transaction ID"
            />
            <Table
                loading={isLoading}
                data={tableData||[]}
                theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                tdataClass="py-6 pl-[10px] text-sm"
                trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                hideTableTop={true}
                hideOverallTableBorder={true}
                hideTableBodyBorder={true}
                hideLines={true}
                paginationContainer="!px-0"
                rows={[
                    {
                        header:'Transaction ID',
                        view: (item) => (
                            <span>
                                {item?._id||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Loan Amount',
                        view: (item) => (
                            <span>
                                {currencyFormatter(item?.floatId?.approvedFloat?.approvedAmount||0)}
                            </span>
                        ),
                    },
                    {
                        header:'Tenure',
                        view: (item) => (
                            <span>
                                {item?.floatId?.approvedFloat?.loanTenure||"0"} months 
                            </span>
                        ),
                    },
                    {
                        header:'Interest',
                        view: (item) => (
                            <span>
                                {item?.floatId?.approvedFloat?.monthlyIntrestRate||"0"} %
                            </span>
                        ),
                    },
                    {
                        header:'Date',
                        view: (item) => (
                            <span>
                                {dayjs(item?.floatId?.acceptedAt).format("DD/MM/YYYY hh:mm A")}
                            </span>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <Status
                                text={item?.accountStatus}
                                type={item?.accountStatus==="open" ? "success":"pending"}
                                addClass="text-sm"
                            />
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <Button
                                text="View"
                                onClick={()=>{
                                    navigate(`/sales-ops/request/${item?.floatId?._id}`)
                                }}
                                className="px-[20px] py-1 !text-sm !rounded bg-ee-primary"
                            />
                        ),
                    },
                ]}
            />
        </div>
    )
} 