import React from 'react'
import requestBlue from '../../images/svg/box/requestBlue.svg'
import requestGreen from '../../images/svg/box/requestGreen.svg'
import requestRed from '../../images/svg/box/requestRed.svg'
import borrower from '../../images/svg/box/borrower.svg'
import bank from '../../images/svg/box/bank.svg'
import user from '../../images/svg/box/user.svg'
import user1 from '../../images/svg/box/users/user1.svg'
import user2 from '../../images/svg/box/users/user2.svg'
import user3 from '../../images/svg/box/users/user3.svg'
import user4 from '../../images/svg/box/users/user4.svg'
import api from '../../images/svg/box/api.svg'
import floatApproved from '../../images/svg/box/float/approved.svg'
import floatRequested from '../../images/svg/box/float/request.svg'
import floatDisbursed from '../../images/svg/box/float/disbursed.svg'
import floatOutstanding from '../../images/svg/box/float/outstanding.svg'
import floatOverdue from '../../images/svg/box/float/overdue.svg'
import cl from '../../images/svg/box/api/cl.svg'
import av from '../../images/svg/box/api/av.svg'
import bv from '../../images/svg/box/api/bv.svg'
import cv from '../../images/svg/box/api/cv.svg'
import nv from '../../images/svg/box/api/nv.svg'
import cr from '../../images/svg/box/api/cr.svg'
import crc from '../../images/svg/box/api/crc.svg'
import fc from '../../images/svg/box/api/fc.svg'
import income from '../../images/svg/box/api/income.svg'
import pr from '../../images/svg/box/api/pr.svg'
import radar from '../../images/svg/box/api/radar.svg'
import appBlue from '../../images/svg/box/app/appBlue.svg'
import appGreen from '../../images/svg/box/app/appGreen.svg'
import wallet from '../../images/svg/box/wallet.svg'
import walletLightGreen from '../../images/svg/box/wallet/walletGreen.svg'
import walletDarkGreen from '../../images/svg/box/wallet/walletDarkGreen.svg'
import walletDarkBlue from '../../images/svg/box/wallet/walletDarkBlue.svg'
import walletLightBlue from '../../images/svg/box/wallet/walletBlue.svg'
import graph from '../../images/svg/box/graph.svg'
import graphRed from '../../images/svg/box/graphRed.svg'
import tech from '../../images/svg/box/tech.svg';
import float from '../../images/svg/box/sales/float.svg';
import outstanding from '../../images/svg/box/sales/outstanding.svg';
import overdue from '../../images/svg/box/sales/overdue.svg';
// import repaymentDate from '../../images/svg/box/sales/date.svg';
import repaymentAmount from '../../images/svg/box/sales/amount.svg';
import { currencyFormatter } from 'utils/helpers'
import dayjs from 'dayjs'

const Box = ({boxData, devBoxData, walletFunded, query, doAction, salesFloat}) => {
  //third-party
  const thirdPartyCount = (boxData?.item?.totalCount ? boxData?.item?.totalCount : 0) + (devBoxData?.totalCount ? devBoxData?.totalCount : 0);
  const thirdPartyCost = (boxData?.item?.totalCost ? boxData?.item?.totalCost : 0) + (devBoxData?.totalCost ? devBoxData?.totalCost : 0);

  var formatMoney = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    //minimumFractionDigits: 0, 
    maximumFractionDigits: 2, 
  });
  return  (

   <>
   {
     //approval
     boxData.boxFor === "approval" ?
    <div className='box_container'>
   
        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={requestBlue} alt="icon" /></div>

          <div className='box_container-box-details'>
          <h5> {boxData?.item?.pending}</h5>
            <p>Request Received</p>
            {/* <p> <img src={graph} alt="icon" />
              <span className='percent'> 4.07%</span> 
              <span className='small-text'> Last month </span> 
            </p> */}
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={requestGreen} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.approved}</h5>
            <p>Business Approved</p>
            {/* <p> <img src={graph} alt="icon" />
              <span className='percent'> 4.07%</span> 
              <span className='small-text'> Last month </span> 
            </p> */}
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={requestRed} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.declined}</h5>
              <p>Request Declined</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={requestRed} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData.item.suspended}</h5>
              <p>Business Suspended</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
          </div>

        </div>
    </div>
    : 
     //users
     boxData.boxFor === "users" ?
    <div className='box_container'>
   
        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user1} alt="icon" /></div>

          <div className='box_container-box-details'>
          <h5> {boxData?.item?.totalUsersOnboarded}</h5>
            <p>Total Users Onboarded</p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user2} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalChecksMade}</h5>
            <p>Total Checks Made</p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user3} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.usersOnboarded}</h5>
              <p>Users Onboarded</p>
              <p style={{display:"flex"}}> 
                <div style={{flex:2}}> 
                  <img src={boxData?.item?.userPercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.userPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.userPercent) || 0}%</span> 
                </div>
              
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
              </p>
          </div>

        </div>

        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={user4} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData.item.checksMade}</h5>
              <p>Checks Made</p>
              <p style={{display:"flex"}}> 
              <div style={{flex:2}}> 
                  <img src={boxData?.item?.checkPercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.checkPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.checkPercent) || 0}%</span> 
                  </div>
              
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
          </div>
        </div>
    </div>
    : 
     //third-party-apps
     boxData.boxFor === "third-party-apps" ?
    <div className='box_container'>
   
        <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={appBlue} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {thirdPartyCount ? thirdPartyCount : 0}</h5>
            <p>Total API Calls</p>
          </div>
        </div>

        <div className='box_container-box'>
          <div className='box_container-box-image'> <img src={appGreen} alt="icon" /></div>
          <div className='box_container-box-details'>
            <h5> { thirdPartyCost ? formatMoney.format(thirdPartyCost) : formatMoney.format(0) }</h5>
            <p>Total Value</p>
          </div>
        </div>
    </div>
    : 

    //apis
    boxData.boxFor === "apis" ?
    <div className='box_container'>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={api} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalApiCalls}</h5>
              <p>Total API Calls</p>
              { query !== 'all' &&
              <p style={{display:"flex"}}> 
             
                <div style={{flex:2}}> 
                    <img src={boxData?.item?.totalApisPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.totalApisPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.totalApisPercent) || 0}%</span> 
                </div>
                <span className='small-text' style={{flex: 1.7}}> {query} </span> 
             
                </p>
               }
            </div>
          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={fc} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.firstCentral}</h5>
              <p>First Central</p>
              { query !== 'all' &&
               <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.firstCentralPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.firstCentralPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.firstCentralPercent) || 0}%</span> 
                  </div>
              
                 <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={radar} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.radar}</h5>
                <p>Radar</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.radarPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.radarPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.radarPercent) || 0}%</span> 
                    </div>
                
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={bv} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.bvnVerication}</h5>
                <p>BVN Verification</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.bvnPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.bvnPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.bvnPercent) || 0}%</span> 
                    </div>

                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={api} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalBillableApiCalls}</h5>
              <p>Total billable API calls</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.totalBillablePercent < 0 ? graphRed : graph} alt="icon" />
                  <span className={boxData?.item?.totalBillablePercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.totalBillablePercent) || 0}%</span> 
                  </div>
              
                   <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={crc} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {boxData?.item?.crc}</h5>
              <p>CRC</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.crcPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.crcPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.crcPercent) || 0}%</span> 
                  </div>
                
                   <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
              }
            </div>
          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={cl} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.classic}</h5>
                <p>Classic</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.classicPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.classicPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.classicPercent) || 0}%</span> 
                    </div>
                
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={income} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.income}</h5>
              <p>Income</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.incomePercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.incomePercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.incomePercent) || 0}%</span> 
                  </div>
                
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={cr} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.creditRegistry}</h5>
                <p>Credit Registry</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                        <img src={boxData?.item?.classicPercent < 0 ? graphRed : graph} alt="icon" />
                        <span className={boxData?.item?.classicPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.classicPercent) || 0}%</span> 
                      </div>
              
                    <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={pr} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {boxData?.item?.premium}</h5>
                <p>Premium</p>
                { query !== 'all' &&
                  <p style={{display:"flex"}}> 
                    <div style={{flex:2}}> 
                      <img src={boxData?.item?.premiumPercent < 0 ? graphRed : graph} alt="icon" />
                      <span className={boxData?.item?.premiumPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.premiumPercent) || 0}%</span> 
                    </div>
              
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                  </p>
                }
            </div>

          </div>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={av} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.accountVerication}</h5>
              <p>Account Number Verification</p>
              { query !== 'all' &&
                <p style={{display:"flex"}}> 
                  <div style={{flex:2}}> 
                    <img src={boxData?.item?.accountVerificationPercent < 0 ? graphRed : graph} alt="icon" />
                    <span className={boxData?.item?.accountVerificationPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.accountVerificationPercent) || 0}%</span> 
                  </div>
                  
                  <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                </p>
              }
            </div>

          </div>

            <div className='box_container-box'>

              <div className='box_container-box-image'> <img src={cv} alt="icon" /></div>

                <div className='box_container-box-details'>
                <h5> {boxData?.item?.cacVerication}</h5>
                  <p>CAC Verification</p>
                  { query !== 'all' &&
                    <p style={{display:"flex"}}> 
                      <div style={{flex:2}}> 
                        <img src={boxData?.item?.cacPercent < 0 ? graphRed : graph} alt="icon" />
                        <span className={boxData?.item?.cacPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.cacPercent) || 0}%</span> 
                      </div>
                
                      <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                    </p>
                  }
              </div>

            </div>

            <div className='box_container-box'>

              <div className='box_container-box-image'> <img src={nv} alt="icon" /></div>

                <div className='box_container-box-details'>
                <h5> {boxData?.item?.ninVerication}</h5>
                  <p>NIN Verification</p>
                  { query !== 'all' &&
                    <p style={{display:"flex"}}> 
                      <div style={{flex:2}}> 
                          <img src={boxData?.item?.ninPercent < 0 ? graphRed : graph} alt="icon" />
                          <span className={boxData?.item?.ninPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.ninPercent) || 0}%</span> 
                        </div>
                
                        <span className='small-text' style={{flex: 1.7}}> {query} </span> 
                    </p>
                  }
              </div>

            </div>
        
    </div>
    :
    //wallet
    boxData.boxFor === "wallet" ?
    <div className='box_container'>
          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={walletLightGreen} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.totalWallets ? (boxData?.item?.totalWallets + walletFunded?.totalWallets) || 0 : walletFunded?.totalWallets ? walletFunded?.totalWallets || 0 : 0 }</h5>
              <p>Total number of Wallets</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>
          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={walletLightGreen} alt="icon" /></div>

            <div className='box_container-box-details'>
              <h5> {boxData?.item?.walletsFunded ? (boxData?.item?.walletsFunded + walletFunded?.walletsFunded) || 0 : walletFunded?.walletsFunded ? walletFunded?.walletsFunded || 0 : 0  }</h5>
                <p>Wallet Funded</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>

          </div>

          <div className='box_container-box'>

          <div className='box_container-box-image'> <img src={walletDarkBlue} alt="icon" /></div>

          <div className='box_container-box-details'>

          <h5>{currencyFormatter(boxData?.item?.totalWalletFunding||0)}</h5> {/** walletFunded?.totalWalletFunding */}
            <p>Total Amount Funded</p>
          </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={walletLightBlue} alt="icon" /></div>

            <div className='box_container-box-details'>
            <h5> {formatMoney.format(boxData?.item?.walletsViaPaystack  ? (boxData?.item?.walletsViaPaystack + walletFunded?.walletsViaPaystack) || 0 : walletFunded?.walletsViaPaystack ? walletFunded?.walletsViaPaystack || 0 : 0)}</h5>
              <p>Wallet Funded via Paystack</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={walletDarkGreen} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.walletsViaMonnify  ? (boxData?.item?.walletsViaMonnify + walletFunded?.walletsViaMonnify) || 0 : walletFunded?.walletsViaMonnify ? walletFunded?.walletsViaMonnify || 0 : 0)}</h5>
                <p>Wallet Funded via Monnify</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>

          </div>

          <div className='box_container-box'>

            <div className='box_container-box-image'> <img src={walletDarkGreen} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.walletsViaProvidus  ? (boxData?.item?.walletsViaProvidus ) : 0)}</h5>
                <p>Wallet Funded via Providus</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>

          </div>
</div>
    :
    //sales-ops
    boxData.boxFor === "sales-ops" ?
    <div className='box_container'>
      <div className='box_container-box'>
        <div className='box_container-box-image'> <img src={api} alt="icon" /></div>
          <div className='box_container-box-details'>
          <h5> {boxData?.item?.totalBillableApis}</h5>
            <p>Total billable API Calls</p>
        </div>
      </div>

      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={borrower} alt="icon" /></div>

        <div className='box_container-box-details'>
          <h5> {boxData?.item?.totalBorrowers}</h5>
            <p>Total Borrowers</p>
            {/* <p> <img src={boxData?.item?.borrowerPercentage < 0 ? graphRed : graph} alt="icon" />
              <span className={boxData?.item?.borrowerPercentage < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.borrowerPercentage) || 0}%</span> 
              <span className='small-text'> Last month </span> 
          </p> */}
        </div>
      </div>

      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={wallet} alt="icon" /></div>

          <div className='box_container-box-details'>
          <h5> {currencyFormatter(boxData?.item?.totalWalletFunding || 0)}</h5>
            <p> Wallet Funding</p>
        </div>

      </div>


      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={walletLightBlue} alt="icon" /></div>

          <div className='box_container-box-details'>
          <h5> {boxData?.item?.wallet?.[0]?.balance ? formatMoney.format(boxData?.item?.wallet?.[0]?.balance) : formatMoney.format(0)}</h5>
            <p>Account Balance</p>
        </div>

      </div>

      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={bank} alt="icon" /></div>

        <div className='box_container-box-details'>
        <h5> {boxData?.item?.totalAccountLinked}</h5>
            <p> Total Bank Accounts Linked</p>
        </div>

      </div>

      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={user} alt="icon" /></div>

          <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalUsers}</h5>
              <p>Business Team Users</p>
          </div>

      </div>

      <div className='box_container-box'>

        <div className='box_container-box-image'> <img src={repaymentAmount} alt="icon" /></div>
          
          <div className='box_container-box-details'>
            <h5> {formatMoney.format(boxData?.item?.usage ? boxData?.item?.usage : 0)}</h5>
              <p>Usage</p>
          </div>

      </div>
      {
        salesFloat?.totalRequest > 0 &&
        <>
          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={float} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {salesFloat.totalRequest}</h5>
                <p>Total Float Requests</p>
            </div>
          </div>

          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={float} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {salesFloat?.totalActiveLoans ? salesFloat?.totalActiveLoans : 0}</h5>
                <p>Active Floats</p>
            </div>
          </div>

          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={repaymentAmount} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(salesFloat?.totalDisbursedLoanAmount ? salesFloat?.totalDisbursedLoanAmount : 0)}</h5>
                <p>Total Disbursed Amount</p>
            </div>
          </div>

          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={repaymentAmount} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(salesFloat?.totalLoanAmount ? salesFloat?.totalLoanAmount : 0)}</h5>
                <p>Total Open Loan Amount</p>
            </div>
          </div>

          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={outstanding} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(salesFloat?.totalOutstanding ? salesFloat?.totalOutstanding : 0)}</h5>
                <p>Total Outstanding</p>
            </div>
          </div>


          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={repaymentAmount} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> 
                {
                  salesFloat?.totalOutstandingPrincipal ? 
                  formatMoney.format(salesFloat?.totalOutstandingPrincipal) : 
                  formatMoney.format(0)
                }
              </h5>
                <p>Total Outstanding Principal</p>
            </div>
          </div>

          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={overdue} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(salesFloat?.totalDue)}</h5>
                <p>Total Overdue</p>
            </div>
          </div>
{/* 
          <div className='box_container-box'>
            <div className='box_container-box-image'> <img src={repaymentAmount} alt="icon" /></div>
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(salesFloat?.nextRepaymentAmount)}</h5>
                <p>Next Repayment Amount</p>
            </div>
          </div> */}

        </>
      }

    </div>
    :
     //tech support
     boxData.boxFor === "tech-support" ?

     <div className='box_container'>
   
       <div className='box_container-box' onClick={()=>doAction('Auth')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> Auth </h5>
           <p>Last updated</p>
           <p>{ boxData?.item?.Authentication && `${dayjs(boxData?.item?.Authentication).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Income')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
             <h5> Income </h5>
             <p>Last updated</p>
            <p>{ boxData?.item?.IncomeInsights && `${dayjs(boxData?.item?.IncomeInsights).format("DD-MM-YYYY, hh:mmA")}`}</p>
           </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Wallet')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
           <h5> Wallet</h5>
           <p>Last updated</p>
            <p>{boxData?.item?.Wallet && `${dayjs(boxData?.item?.Wallet).format("DD-MM-YYYY, hh:mmA")}`}</p>
             
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Credit')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> Credit </h5>
            <p>Last updated</p>
            <p>{ boxData?.item?.Creditinsights && `${dayjs(boxData?.item?.Creditinsights).format("DD-MM-YYYY, hh:mmA")}`}</p>
          </div>

       </div>

         {/* numbers... affected by filter */}

       <div className='box_container-box' onClick={()=>doAction('Identity')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> Identity </h5>
            <p>Last updated</p>
            <p>{ boxData?.item?.Identity && `${dayjs(boxData?.item?.Identity).format("DD-MM-YYYY, hh:mmA")}`}</p>
        </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Radar')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
              <h5> Radar</h5>
              <p>Last updated</p>
              <p>{ boxData?.item?.Radar && `${dayjs(boxData?.item?.Radar).format("DD-MM-YYYY, hh:mmA")}`}</p>
           </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Pdf Parser')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
            <h5> Pdf Parser</h5>
            <p>Last updated</p>
            <p>{ boxData?.item?.PDFParser && `${dayjs(boxData?.item?.PDFParser).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('DBR & EDTI')} style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> DBR & EDTI</h5>
           <p>Last updated</p>
            <p>{ boxData?.item?.dbrEDTI && `${dayjs(boxData?.item?.dbrEDTI).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('B2BFrontend')} style={{cursor:"pointer"}} >

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> B2BFrontend</h5>
         <p>Last updated</p>
            <p>{ boxData?.item?.FrontendB2B && `${dayjs(boxData?.item?.FrontendB2B).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Float')} style={{cursor:"pointer"}} >

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> Float </h5>
         <p>Last updated</p>
            <p>{ boxData?.item?.Float && `${dayjs(boxData?.item?.Float).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('MFB')} style={{cursor:"pointer"}} >

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> MFB </h5>
         <p>Last updated</p>
            <p>{ boxData?.item?.Float && `${dayjs(boxData?.item?.MFB).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>

       </div>

       <div className='box_container-box' onClick={()=>doAction('Spectrum')} style={{cursor:"pointer"}} >

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
         <h5> Spectrum </h5>
         <p>Last updated</p>
            <p>{ boxData?.item?.Float && `${dayjs(boxData?.item?.DLN).format("DD-MM-YYYY, hh:mmA")}`}</p>
         </div>
       </div>

        <div className='box_container-box' onClick={()=>doAction('EagleEyeAPI')} style={{cursor:"pointer"}} >
          <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
          <div className='box_container-box-details'>
          <h5>Eagleeye</h5>
          <p>Last updated</p>
            <p>{ boxData?.item?.EagleEyeAPI && `${dayjs(boxData?.item?.EagleEyeAPI).format("DD-MM-YYYY, hh:mmA")}`}</p>
          </div>
        </div>

     </div>
     :
     //metrics
     boxData.boxFor === "metrics" ?

     <div className='box_container'>
   
       <div className='box_container-box' style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
         <div className='box_container-box-details'>
         <h5> {boxData?.item?.numberOfBusinesses}</h5>
         <p style={{fontWeight: 800}}>Business Growth </p>
         <p style={{display:"flex"}}> 
              <div style={{flex:2}}> 
                <img src={boxData?.item?.businessPercent < 0 ? graphRed : graph} alt="icon" /> 
                <span className={boxData?.item?.businessPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.businessPercent) || 0}%</span> 
              </div>
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
              </p>
          </div>
       </div>
   
       <div className='box_container-box' style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
         <div className='box_container-box-details'>
         <h5> {currencyFormatter(boxData?.item?.numberOfWalletFunding||0)}</h5>
         <p style={{fontWeight: 800}}>Revenue Growth</p>
         <p style={{display:"flex"}}> 
              <div style={{flex:2}}> 
                <img src={boxData?.item?.walletPercent < 0 ? graphRed : graph} alt="icon" />
                <span className={boxData?.item?.walletPercent < 0 ? 'percentRed' : 'percent'}> {Math.abs(boxData?.item?.walletPercent) || 0}%</span> 
              </div>
              <span className='small-text' style={{flex: 1.7}}> {query} </span> 
              </p>
          </div>
       </div>

       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
           <h5> {boxData?.item?.retentionRate ? Math.abs(boxData?.item?.retentionRate) : 0}%</h5>
           <p style={{fontWeight: 800}}> New Business Retention Rate </p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
         </div>

       </div>

       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> {boxData?.item?.churnRate ? Math.abs(boxData?.item?.churnRate) : 0}%</h5>
            <p style={{fontWeight: 800}}> New Business Churn Rate</p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
          </div>
       </div>


       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> {boxData?.item?.businessCAGR ? Math.abs(boxData?.item?.businessCAGR*100).toFixed(2) : 0}%</h5>
            <p style={{fontWeight: 800}}> Business CAGR </p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
          </div>
       </div>

       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> {boxData?.item?.revenueCAGR ? Math.abs(boxData?.item?.revenueCAGR *100).toFixed(2) : 0}%</h5>
            <p style={{fontWeight: 800}}> Revenue CAGR </p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
          </div>
       </div>

       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

           <div className='box_container-box-details'>
           <h5> {boxData?.item?.totalRetentionRate ? Math.abs(boxData?.item?.totalRetentionRate) : 0}%</h5>
           <p style={{fontWeight: 800}}> Total Business Retention Rate </p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
         </div>

       </div>

       <div className='box_container-box'  style={{cursor:"pointer"}}>

         <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>

         <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalChurnRate ? Math.abs(boxData?.item?.totalChurnRate) : 0}%</h5>
            <p style={{fontWeight: 800}}> Total Business Churn Rate </p>
            <p className='small-text' style={{flex: 1.7}}> {query} </p> 
          </div>
       </div>

      <div className='box_container-box'  style={{cursor:"pointer"}}>
        <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
        <div className='box_container-box-details'>
          <h5>{formatMoney.format(boxData?.item?.incomeRevenue || 0)}</h5>
          <p style={{fontWeight: 800}}> Total Income Revenue </p>
          <p className='small-text' style={{flex: 1.7}}> {query} </p> 
        </div>
      </div>

      <div className='box_container-box'  style={{cursor:"pointer"}}>
        <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
        <div className='box_container-box-details'>
          <h5>{formatMoney.format(boxData?.item?.identityRevenue || 0)}</h5>
          <p style={{fontWeight: 800}}> Total Identity Revenue </p>
          <p className='small-text' style={{flex: 1.7}}> {query} </p> 
        </div>
      </div>

      <div className='box_container-box'  style={{cursor:"pointer"}}>
        <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
        <div className='box_container-box-details'>
          <h5>{formatMoney.format(boxData?.item?.creditRevenue || 0)}</h5>
          <p style={{fontWeight: 800}}> Total Credit Revenue </p>
          <p className='small-text' style={{flex: 1.7}}> {query} </p> 
        </div>
      </div>

      <div className='box_container-box'  style={{cursor:"pointer"}}>
        <div className='box_container-box-image'> <img src={tech} alt="icon" /></div>
        <div className='box_container-box-details'>
          <h5>{formatMoney.format(boxData?.item?.radarRevenue || 0)}</h5>
          <p style={{fontWeight: 800}}> Total Radar Revenue </p>
          <p className='small-text' style={{flex: 1.7}}> {query} </p> 
        </div>
      </div>

     </div>
    :
      //float
      boxData.boxFor === "float" ?
      <div className='box_container'>
     
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatRequested} alt="icon" /></div>
  
            <div className='box_container-box-details'>
            <h5> {boxData?.item?.totalRequestReceived}</h5>
              <p>Total Request Received</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatApproved} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {boxData?.item?.totalApproved}</h5>
              <p>Total Approved</p>
              {/* <p> <img src={graph} alt="icon" />
                <span className='percent'> 4.07%</span> 
                <span className='small-text'> Last month </span> 
              </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueDisbursed ? boxData?.item?.totalValueDisbursed : 0)}</h5>
                <p>Total Value Disbursed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOutstanding} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOutstanding ? boxData?.item?.totalOutstanding : 0)}</h5>
                <p>Total  Outstanding</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOverdue} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOverdue ? boxData?.item?.totalOverdue : 0)}</h5>
                <p>Total Overdue</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueRepayed ? boxData?.item?.totalValueRepayed : 0)}</h5>
                <p>Total Value Repayed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.expectedCashflow ? boxData?.item?.expectedCashflow : 0)}</h5>
                <p>Expected Cashflow</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
      </div>
    :
      //float-history
      boxData.boxFor === "float-history" ?
      <div className='box_container'>
  
          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
            <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueDisbursed ? boxData?.item?.totalValueDisbursed : 0)}</h5>
                <p>Total Value Disbursed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>

          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatDisbursed} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalValueRepayed ? boxData?.item?.totalValueRepayed : 0)}</h5>
                <p>Total Value Repayed</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
  
         
          <div className='box_container-box'>
  
          <div className='box_container-box-image'> <img src={floatOutstanding} alt="icon" /></div>

              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOutstanding ? boxData?.item?.totalOutstanding : 0)}</h5>
                <p>Total  Outstanding</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>

          </div>

          <div className='box_container-box'>
  
            <div className='box_container-box-image'> <img src={floatOverdue} alt="icon" /></div>
  
              <div className='box_container-box-details'>
              <h5> {formatMoney.format(boxData?.item?.totalOverdue ? boxData?.item?.totalOverdue : 0)}</h5>
                <p>Total Overdue</p>
                {/* <p> <img src={graph} alt="icon" />
                  <span className='percent'> 4.07%</span> 
                  <span className='small-text'> Last month </span> 
                </p> */}
            </div>
  
          </div>
          
      </div>
    :<div></div>
}
    </>
  )
}

export default Box
