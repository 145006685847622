import clsx from "clsx";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function Tabs ({
    tabs, 
    useAsLink=false,
    clearSearch // optional
}) {
    const location = useLocation();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const changePath = (value) => {
        if(useAsLink){
            navigate(value)
        } else {
            if(clearSearch){
                searchParams.set('search', '');
            }
            searchParams.set('tab', value);
            setSearchParams(searchParams);
        }
    }

    // if it is used as link redirect to page, if not add it to query param
    
    return (
        <div className="hide-scrollBar border-b-[1px] border-solid border-[#B5B5B5] flex overflow-x-scroll box-border">
            {
                tabs.map(item=>(
                    <p
                        className={clsx(
                            "mr-[30px] mb-0 whitespace-nowrap pb-2.5 pt-[20px] cursor-pointer text-black",
                            (location?.pathname===item?.key || searchParams.get("tab")===item?.key) && "border-b-[3px] border-solid border-ee-primary",
                        )}
                        onClick={()=>changePath(item.key)}
                    >
                        {item.label}
                    </p>
                ))
            }
        </div>
    )
}