import axios from 'axios';

let baseURL = process.env.REACT_APP_ENVIRONMENT === "production" ?
    process.env.REACT_APP_PRODUCTION 
    : 
    process.env.REACT_APP_LOCAL;
let userRole = JSON.parse(sessionStorage.getItem('eagleEye_details'))?.adminRole
let adminName = JSON.parse(sessionStorage.getItem('adminName'))

export const loadToken = () => {
    let token = '';
    try {
        token = JSON.parse(sessionStorage.getItem('eagleEye_details')).token;
    } catch (err) {
        //err
    }
    return token;
}

export const APIKit = (timeout=10000) => axios.create({
    baseURL,
    timeout: timeout,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'role': userRole,
        'name': adminName
    },
});