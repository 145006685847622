import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { thirdPartyStats, lifeGateStats, devThirdPartyStats, devLifeGateStats } from '../../../lib/auth';
import Box from '../../Box'
import ThirdPartyBox from '../../Box/ThirdPartyBox';
import SelectInput from '../../Form/SelectInput';
import { Modal } from "react-bootstrap";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';

const ThirdPartyApps = ({roles, setDetailName}) => {
  const [query, setQuery] = useState('all')
  // const [queryVal, setQueryVal] = useState('This month')
  const [startDateee, setStartDateee] = useState("");
  // const [customStartDate, setCustomStartDate] = useState("");
  // const [customEndDate, setCustomEndDatee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [show, setShow] = useState(false);
  const [devBoxData, setDevBoxData] = useState([]);
  const [devLifeGateData, setDevLifeGateData] = useState([]);

  const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

useEffect(() => {
  setDetailName("");
}, [setDetailName]);

const handleClose = () => setShow(false);

var today = new Date();
var d;
var month = [];
// let monthSlice = [];

for(var i = 6; i > 0; i -= 1) {
  d = new Date(today.getFullYear(), today.getMonth() - i, 1);
  month.push(months[d.getMonth()]);
  // monthSlice = mon
}

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

  const [boxData, setBoxData] = useState({
    boxFor: "third-party-apps",
    item: []
  });
  const [lifeGateData, setLifeGateData] = useState({});

  let adminRole = ''

  try {
      let details = sessionStorage.getItem('eagleEye_details');
      adminRole = JSON.parse(details).adminRole;
  } catch (err) {
      //err
  }
  
  useEffect(() => {
    // setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    // setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)

    if(adminRole!=="Life Gate"){
      thirdPartyStats(query)
        .then(response => response.data)
        .then((res) => {
          if(res.status === 'success'){
            setBoxData({
              ...boxData, 
              item:res.data
            })
          }    else {
            toast.error("Something went wrong, please try again!!!")
          }
        })
        .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
        });
    }

    lifeGateStats(query)
      .then(response => response.data)
      .then((res) => {
        if(res.status === 'success'){
          setLifeGateData(res.data)
        }    else {
          toast.error("Something went wrong, please try again!!!")
        }
      })
      .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  
  useEffect(() => {
    // setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    // setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)

    if(adminRole!=="Life Gate"){
      devThirdPartyStats(query)
        .then(response => response.data)
        .then((res) => {
          if(res.success === true){
            setDevBoxData(res?.data)
          }  else {
            toast.error("Something went wrong, please try again!!!")
          }
        })
        .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
        });
    }

    devLifeGateStats(query)
      .then(response => response.data)
      .then((res) => {
        if(res?.success === true){
          setDevLifeGateData(res?.data)
        }else {
          toast.error("Something went wrong, please try again!!!")
        }
      })
      .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

    const handleSelectChange = (name, val) => {
      if
      (val === 'All'){
        setQuery('all')
        // setQueryVal('all')
      }else if
      (val=== 'Last 7 days'){
        setQuery('last_seven_days')
        // setQueryVal('Last 7 days')
      }else if(val === 'This Month'){
        setQuery('this_month')
        // setQueryVal('This Month')
      }else if(val === 'Last Month'){
        setQuery('last_month')
        // setQueryVal('Last Month')
      }else if(val === 'Last 3 Months'){
        setQuery('last_three_months')
        // setQueryVal('Last 3 Months')
      }else if(val === 'Last 6 Months'){
        setQuery('last_six_months')
      }else if(val === 'Last 12 Months'){
        setQuery('last_twelve_months')
      }else if(val === 'Custom'){
        // setQuery('custom')
        // setQueryVal('Custom')
        setShow(true)
      }
      }

  return (
    <div className='home_main'>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                      setStartDateee(formatPickerDate(item.selection.startDate))
                      setEndDateee(formatPickerDate(item.selection.endDate))
                      setState([item.selection])
                  }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      // setQueryVal(`${customStartDate} - ${customEndDate}`)
                  }}
              >Proceed</button>
      </Modal>
      <div className='w-[150px] float-right -mt-[70px]'>
          <SelectInput 
              placeholder={"All"}
              options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
          />
      </div>
      {
        adminRole!=="Life Gate"  && 
        <Box boxData={boxData} devBoxData={devBoxData} />
      }

      <div>
        <ThirdPartyBox boxData={boxData} lifeGate={lifeGateData} devBoxData={devBoxData} devLifeGateData={devLifeGateData}  />
      </div>
    </div>
  )
}

export default ThirdPartyApps