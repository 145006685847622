import { useNavigate, useParams } from "react-router-dom";
import Back from '../../back'

function Details ({setDetailName}) {
    let params = useParams()
    let navigate = useNavigate()

    console.log(params)
    let businessData = JSON.parse(sessionStorage.getItem('businessDetails')).businessData
    console.log(businessData)
    var getInitials = function (string) {
        var names = string?.split(' '),
            initials = names?.[0]?.substring(0, 1).toUpperCase();
        
        // if (names?.length > 1) {
        //     initials += names?.[names?.length - 1]?.substring(0, 1).toUpperCase();
        // }
        return initials;
    };
    

    return (
        <div className="home_main">
            <div className="accounts">
            <Back to={()=> navigate("/approvals")} />
                 
                <div className="accounts_details">
                    
                        <div className="accounts_name">
                            <div className="accounts_name-tag">
                                <p>{getInitials(`${businessData?.businessName}`)}</p>
                            </div>
                            <h3>{businessData?.businessName}</h3>
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Business Name</p>
                                <p className="text2">{businessData?.businessName}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">RC Number</p>
                                <p className="text2">{businessData?.CAC ? businessData?.CAC : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Business Owner</p>
                                <p className="text2">{businessData?.ownerFullName ? businessData?.ownerFullName : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Business Sector</p>
                                <p className="text2">{businessData?.businessSector}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">BVN</p>
                                <p className="text2">{businessData?.bvn ? businessData?.bvn : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">NIN</p>
                                <p className="text2">{businessData?.nin ? businessData?.nin : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Phone</p>
                                <p className="text2">{businessData?.phoneNumber}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Email</p>
                                <p className="text2">{businessData?.email}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                       

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Size</p>
                                <p className="text2">{businessData?.size ? businessData?.size : 'Nil' }</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Registration Type</p>
                                <p className="text2">{businessData?.businessType }</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Country</p>
                                <p className="text2">{businessData?.country}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">State</p>
                                <p className="text2">{businessData?.state}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">LGA</p>
                                <p className="text2">{businessData?.lga}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Address</p>
                                <p className="text2">{businessData?.address}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Compliance status</p>
                                <p className="text2">{businessData?.isComplied === true ? 'True' : "False"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Website</p>
                                <p className="text2">{businessData?.website ? businessData?.website : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            
                    
                        </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Details;