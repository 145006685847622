import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { approvalAction, approvalRequests, approvalStats, changeSpectrumStatus, deleteBusiness, getSpectrumRequests, viewBusiness } from '../../../lib/auth';
import { toast, ToastContainer } from 'react-toastify';
import Box from '../../Box'
import Table from '../../Table/general.js';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../Form/SelectInput';
import DeleteModal from '../../Modal/DeleteModal';
import { getGeolocation } from "../../../lib/auth";
import { CSVLink } from 'react-csv';
import Loader from '../../shared/Loader';
import download from '../../../images/svg/download.svg';
import search from '../../../images/svg/search.svg'
import { useNavigate } from 'react-router-dom';


const Main = () => {
  let navigate = useNavigate();
    const [query, setQuery] = useState('all')
    const [queryVal, setQueryVal] = useState('all')
    const [requestPath, setRequestPath] = useState("pending");
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showProfileModal, setShowProfileModal] = useState(false)
    const [businessId, setBusinessId] = useState('')
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [isBoxLoading, setIsBoxLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [ipDeets, setIpDeets] = useState({
      country_name: "Nigeria",
      IPv4: "102.89.45.161"
    });
    const [spectrumPath, setSpectrumPath] = useState("");

    let path = ''

    try {
      path = sessionStorage.getItem('path')
    } catch (error) {}

    useEffect(() => {
      setRequestPath(path ? path : 'pending')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);

    const handleClose = () => setShow(false);

    function formatPickerDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('-');
    }

    const [boxData, setBoxData] = useState({
      boxFor: "approval",
      item: []
    });
    const [data, setData] = useState({
        tableFor: "approval",
        headings: ["Business Name", "Sector", "Email",  "Phone Number", "Compliance status", "Date", "Country", "Action"],
        items: []
    });

  useEffect( () => {
    getGeolocation()
    .then(response => response.data)
    .then((res) => {
        setIpDeets(res)
    })
    .catch(()=>{})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    setIsTableLoading(true)
    
    if(requestPath!==""){
      approvalRequests(requestPath, query)
          .then((response) => response.data)
          .then((res) => {
              if (res.status === 'success') {
                  setFilteredData(res.data)
              } else {
                  toast.error("Something went wrong, please try again!!!")
                  setIsTableLoading(false)
              }
          })
          .catch((err) => {
              if(err.response){
                  toast.error(err.response.data.message);
                  setIsTableLoading(false)
              } else{
                  toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                  setIsTableLoading(false)
              }
          });
          
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[requestPath, query])

  useEffect(()=>{
    setIsTableLoading(true)
    if(spectrumPath!==""){
      getSpectrumReqs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spectrumPath])

  useEffect(() => {
    const filtered = filteredData?.filter((item) => 
    item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
    item?.businessSector?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
    item?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    item?.phoneNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
    )
    setData({
      tableFor: "approval",
      headings: ["Business Name", "Sector", "Email",  "Phone Number", "Compliance status", "Date", "Country", "Action"],
      items: filtered
    })
    setIsTableLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filteredData])

  const handleSelectChange = (name, val) => {
    if
    (val === 'All'){
      setQuery('all')
    }else if
    (val=== 'Last 7 days'){
      setQuery('last_seven_days')
      setQueryVal('Last 7 days')
    }else if(val === 'This Month'){
      setQuery('this_month')
      setQueryVal('This Month')
    }else if(val === 'Last Month'){
      setQuery('last_month')
      setQueryVal('Last Month')
    }else if(val === 'Last 3 Months'){
      setQuery('last_three_months')
      setQueryVal('Last 3 Months')
    }else if(val === 'Last 6 Months'){
      setQuery('last_six_months')
      setQueryVal('Last 6 Months')
    }else if(val === 'Last 12 Months'){
      setQuery('last_twelve_months')
      setQueryVal('Last 12 Months')
    }else if(val === 'Custom'){
      setQuery('custom')
      setQueryVal('Custom')
      setShow(true)
    }
  }

  useEffect(()=>{
    setIsBoxLoading(true)
    approvalStats(query)
      .then((response) => response.data)
      .then((res) => {
        if (res.status === 'success') {
            setBoxData({
              ...boxData,
              item:res.data
            })
            setIsBoxLoading(false)
        } else {
            toast.error("Something went wrong, please try again!!!")
            setIsBoxLoading(false)
        }
      })
      .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
            setIsBoxLoading(false)
        } else{
            toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            setIsBoxLoading(false)
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[query])

  const toggleStatus = (id, status) => {

    let payload = {
      businessId: id,
      status,
    }
    approvalAction(payload, ipDeets)
    .then(response => response.data)
    .then((res) => {
      if(res.data.isActive  === "approved") {
        toast.success(res.message)
        setTimeout(() => {
        window.location.reload();
        }, 1500);
      } 
      else if(res.data.isActive  === "declined") {
        toast.success(res.message)

        setTimeout(() => {
        window.location.reload();
        }, 1500);
      } 
      else if(res.data.isActive === "suspended") {
        toast.success(res.message)
        setTimeout(() => {
        window.location.reload();
        }, 1500);
      }  
      else if(res.data.isActive === "deleted") {
        toast.success(res.message)
        setTimeout(() => {
        window.location.reload();
        }, 1500);
      }  
      
      else {
        toast.error("Something went wrong, please try again!!!")
    }
})
.catch((err) => {
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});
}

  const handleDelete = (id) => {
    deleteBusiness(id, ipDeets)
    .then(response => response.data)
    .then((res) => {
      if(res.status === 'success'){
        toast.success(res.message)
        setTimeout(() => {
          window.location.reload();
          setShowProfileModal(false)
          }, 1500);
      }
      else {
        toast.error("Something went wrong, please try again!!!")
    }
})
.catch((err) => {
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
});
  }

  const viewBusinessInfo = (id) => {
      viewBusiness(id)
        .then(response => response.data)
        .then((res) => {
          if(res.status === 'success'){
            sessionStorage.setItem( "businessDetails", JSON.stringify({ businessData: res.data }));
            navigate(`/approvals/${res.data.businessName}`);
          }
          else {
            toast.error("Something went wrong, please try again!!!")
        }
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
      });
  }
    
  const viewSpectrumInfo = (id) => {
    navigate(`/approvals/spectrum/${id}`)
  }

  const toggleSpectrumStatus = (id, status) => {
    let payload = {
      businessId: id,
      status,
    }
    changeSpectrumStatus(payload)
      .then(response => response.data)
      .then((res) => {
        toast.success(res.message)
        getSpectrumReqs()
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
          }
      });
  }

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleDeleteChange = (id) => {
    setBusinessId(id)
    setShowProfileModal(true)
  }

  const getSpectrumReqs = () => {
    getSpectrumRequests(spectrumPath)
    .then((response) => response.data)
    .then((res) => {
      setIsTableLoading(false)
      setData({
        tableFor: 'spectrum-approval',
        headings: ["Business Name", "Email",  "Phone Number", "Date", "Country", "Action"],
        items: res.data
      })
    })
    .catch((err) => {
      setIsTableLoading(false)
      if(err.response){
          toast.error(err.response.data.message);
      } else{
          toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      }
    });
  }

    return (
      <div className='home_main'>
        <ToastContainer />

        <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                  setStartDateee(formatPickerDate(item.selection.startDate))
                  setEndDateee(formatPickerDate(item.selection.endDate))
                  setState([item.selection])
                }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                  }}
              >Proceed</button>
        </Modal>

        <div className='w-[150px] float-right -mt-[70px]'>
          <SelectInput 
              placeholder={"All"}
              options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
          />
        </div>
        {
          isBoxLoading ?
          <Loader /> :
          <Box boxData={boxData} query={queryVal} />
        }
  
       
        <div className='home_main-tableHeader' >
          <div className="table_top" style={{display:"flex", alignItems:"center"}}>
              <div>
                <h4>Approval Requests</h4>
              </div>
              <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                  <input placeholder="Search approvals" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                  />
                  <img src={search} alt="search" />
              </div>
          </div>
        </div>
  
        <div className="settings_navMain approvals_nav hide-horizontalScrollBar">
  
          <p className={requestPath==="pending"?"active":""} 
              onClick={()=> {
                setRequestPath("pending")
                sessionStorage.setItem('path', 'pending')
                setSpectrumPath('')
              }}
          >Pending</p>
          <p className={requestPath==="approved"?"active":""} 
              onClick={()=> {
                setRequestPath("approved")
                sessionStorage.setItem('path', 'approved')
                setSpectrumPath('')
              }}
          >Approved</p>
          <p className={requestPath==="declined"?"active":""} 
              onClick={()=> { 
                setRequestPath("declined")
                sessionStorage.setItem('path', 'declined')
                setSpectrumPath('')
              }}
          >Declined</p>
          <p className={requestPath==="suspended"?"active":""} 
              onClick={()=> { 
                setRequestPath("suspended")
                sessionStorage.setItem('path', 'suspended')
                setSpectrumPath('')
              }}
          >Suspended</p>
          <p className={spectrumPath==="pending"?"active":""} 
              onClick={()=> { 
                setSpectrumPath("pending")
                setRequestPath("")
              }}
          >Spectrum Pending</p>
          <p className={spectrumPath==="approved"?"active":""} 
              onClick={()=> { 
                setSpectrumPath("approved")
                setRequestPath("")
              }}
          >Spectrum Approved</p>
          <p className={spectrumPath==="suspended"?"active":""} 
              onClick={()=> { 
                setSpectrumPath("suspended")
                setRequestPath("")
              }}
          >Spectrum Suspended</p>
          <p className={spectrumPath==="declined"?"active":""} 
              onClick={()=> { 
                setSpectrumPath("declined")
                setRequestPath("")
              }}
          >Spectrum Declined</p>
        </div> 
        {
          isTableLoading ?
          <Loader /> :
          <Table 
            data={data} 
            doAction={(id, status, type)=>{
              type==="approval"?toggleStatus(id, status):toggleSpectrumStatus(id, status);
            }}
            handleDeleteChange ={handleDeleteChange}
            viewBusinessInfo={(id, type)=>{
              type==="approval"?viewBusinessInfo(id):viewSpectrumInfo(id)
            }}
          />
        }

       
          <div className='home_main-csv' style={{marginTop: data.items.length<11 ? "-40px":"-70px"}} >
            { 
              data.items.length > 0 &&
              <CSVLink data={data.items} filename={`${requestPath}-approvals.csv`} >
                <img src={download} alt="download" />CSV
              </CSVLink>
            }
          </div>

          {showProfileModal && <DeleteModal cancel={()=>{setShowProfileModal(false);}} doAction={()=> handleDelete(businessId)} />}
  
      </div>
    )
  }

export default Main