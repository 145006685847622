import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import { userConsentStats } from '../../../lib/auth';
import search from '../../../images/svg/search.svg'
import Table from '../../Table/general.js';
import SelectInput from '../../Form/SelectInput';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { CSVLink } from 'react-csv';
import download from '../../../images/svg/download.svg';
import Loader from '../../shared/Loader';


const UserConsent = ({setDetailName}) => {
  const [query, setQuery] = useState('all')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [startDateee, setStartDateee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

useEffect(() => {
  setDetailName("");
}, [setDetailName]);

const handleClose = () => setShow(false);

  const [tableData, setTableData] = useState({
    tableFor: "user-consent",
    headings: ["ID", "Full Name", "Business Name", "Endpoint", "Service Status", "Date"],
    items: []
});

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

useEffect(() => {
  setIsLoading(true)
  userConsentStats(query)
  .then(response => response.data)
  .then((res) => {
    if(res.status === 'success'){
      console.log(res.data)
      setFilteredData(res?.data?.userConsentData);
      setIsLoading(false)
    } else {
      toast.error("Something went wrong, please try again!!!")
      setIsLoading(false)
  }
})
.catch((err) => {
  if(err.response){
      toast.error(err.response.data.message);
      setIsLoading(false)
  } else{
      toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      setIsLoading(false)
  }
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [query])

useEffect(() => {

  const filtered = filteredData?.filter((item) => 
  item?.borrowerId?.fullname?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
  item?.businessId?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||  
  item?.action?.toLowerCase()?.includes(searchTerm.toLowerCase())
  ) 
  setTableData({
    ...tableData,
    items: filtered
  })
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchTerm, filteredData])

const handleChange = (e) => {
  setSearchTerm(e.target.value)
}


const handleSelectChange = (name, val) => {
  if
  (val === 'All'){
    setQuery('all')
  }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
  }else if(val === 'This Month'){
    setQuery('this_month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
  }else if(val === 'Custom'){
    setQuery('custom')
    setShow(true)
  }
}

  const CSVHeaders = [
    { label: "Full Name", key: "borrowerId.fullname" },
    { label: "Business Name", key: "businessId.businessName" },
    { label: "Endpoint", key: "action" },
    { label: "Date", key: "createdAt" },
  ]

  return (
    <div className='home_main'>
          <ToastContainer />
          <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                      setStartDateee(formatPickerDate(item.selection.startDate))
                      setEndDateee(formatPickerDate(item.selection.endDate))
                      setState([item.selection])
                  }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                  }}
              >Proceed</button>
      </Modal>

        <div className='w-[150px] float-right -mt-[70px]'>
              <SelectInput 
                  placeholder={"All"}
                  options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                  selectChange={(item)=>handleSelectChange("Query", item)}
              />
        </div>
        <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                <input placeholder="Search consents" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                />
                <img src={search} alt="search" />
            </div>

        {
          isLoading ?
            <Loader /> :
            <Table 
                data={tableData} 
            />
        }
          
          <div className='home_main-csv' >
            { 
              tableData.items.length > 0 &&
              <CSVLink data={tableData.items} filename={"user-consent.csv"} headers={CSVHeaders} >
                <img src={download} alt="download" />CSV
              </CSVLink>
            }
          </div>
      
      
      </div>
  )
}

export default UserConsent