import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import BusinessesModal from "./components/BusinessesModal";
import dot from '../../../images/svg/dot.svg'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { sendEmails } from '../../../lib/auth';
import { toast } from 'react-toastify';

function EmailBlast () {
    const [data, setData] = useState({
        title: '',
        subject: '',
        contacts: [],
        body: ''
    })

    const [
        // query, 
        setQuery] = useState('all')
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [body, setBody] = useState('');
    
    const handleClose = () => setShow(false);
    

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date();
    var d;
    var month = [];

    for(var i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month.push(monthNames[d.getMonth()]);
    }
   
    const handleFormChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
    }

    const handleSubmit = () => {
        if(data?.title === "" || data.subject === "" || data.contacts?.length < 1 || body === ""){
            toast.error("please fill every field")
            return
        }

        const payload = {
            ...data,
            body: body
        }
        sendEmails(payload)
            .then(response => response.data)
            .then((res) => {
            if(res.status === 'success'){
                toast.success(res.message || "Email sent successfully");
            
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
                
            }    else {
                toast.error("Something went wrong, please try again!!!")
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
    }

    // onChange expects a function with these 4 arguments
    function handleChange(content, delta, source, editor) {
        setBody(content);
    }

    // const modules = {
    //     mention: {
    //       allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //       mentionDenotationChars: ['[','[['],
    //       source: (searchTerm, renderList) => {
    //         // Fetch your suggestions here based on the searchTerm
    //         const suggestions = ['John Doe', 'Jane Smith', 'Bob Johnson'];
    //         renderList(suggestions, searchTerm);
    //       },
    //     },
    // }

    return(
        <div className='home_main'>

            <Modal show={show} onHide={handleClose}>
              <DateRange
                  editableDateInputs={true}
                  onChange={item => {
                        setStartDateee(formatPickerDate(item.selection.startDate))
                        setEndDateee(formatPickerDate(item.selection.endDate))
                        setState([item.selection])
                      }
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={state}
              />
              <button 
                  style={{
                      color:"white",
                      padding:"7px 25px",
                      textAlign:"center",
                      border:"1px solid #0046E6",
                      background:"#0046E6"
                  }} 
                  onClick={()=>{   
                          handleClose();
                          setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      }}
                  >Proceed</button>
          </Modal>
            <div className='home_wallet-topup-form' id="example" style={{width: "500px"}}>
                <div>
                    <label>Email Title</label><br/>
                    <div >
                        <input placeholder='Title' name="title" onChange={handleFormChange} />
                    </div>
                </div>
                <div>
                    <label>Email Subject</label><br/>
                    <div>
                        <input placeholder='Subject' name="subject" onChange={handleFormChange} />
                    </div>
                </div>
                <div>
                    <label>Select Business Recipients</label><br/>
                    <div onClick={()=>setShowModal(true)}>
                        <input placeholder={data?.contacts?.length > 0 ? `${data?.contacts?.length} Business Recipient(s) Selected` : 'Click To Select'} name="contacts" onChange={handleFormChange} />
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap", marginBottom: "1rem"}}> 
                        {
                            data?.contacts?.map((contact, idx) => (
                                <div style={{display: "flex", marginRight: "5px", alignItems: "center",}} key={idx}>
                                    <img src={dot} alt="dot icon" />
                                    <p style={{fontWeight: 400, marginBottom:0, marginLeft: "2px", fontSize: "12px"}}> {contact?.businessName}</p>
                                </div>
                            ) )
                        }
                    </div>
                </div>
                <div>
                    <label>Email Body</label><br/>
                    <div>
                        <ReactQuill 
                            theme="snow" 
                            value={body} 
                            onChange={handleChange} 
                            // formats={"mention"}
                            // modules={modules}
                        />
                    </div>
                </div>
                <button className='home_wallet-topup-form-button' style={{marginTop: "1.5rem"}} onClick={handleSubmit}>Send Email(s)</button>
            </div>

            {
                showModal && 
                    <BusinessesModal 
                        setShowModal={setShowModal} 
                        setValues={setData} 
                        values={data}
                    />
            }
        </div>
    )
}

export default EmailBlast