import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { currencyFormatter, dateFormatter } from "utils/helpers";
import Table from "components/Table";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { RecovaService } from "services/recova.service";
import { useSearchParams } from "react-router-dom";


export default function MandateSection () {
  const [dropDownView, setDropDownView] = useState(null)
  const [graphData, setGraphData] = useState([])
  const [mandates, setMandates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? 1
  const search = searchParams.get("search")


  useEffect(()=>{
    setIsLoading(true)
    RecovaService.getMandates(page, search)
      .then((response) => response.data)
      .then((res) => {
          setIsLoading(false)
          setMandates(res.data.businessMandates)
          const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
            daysRange,
            count
          }));
          setGraphData(formattedGraphData)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  },[page, search])

  function getMonthsBetweenDates(date1_, date2_) {
    let date1 = new Date(date1_)
    let date2 = new Date(date2_)
    if (date1 > date2) {
      let temp = date1;
      date1 = date2;
      date2 = temp;
    }
    
    let yearsDifference = date2?.getFullYear() - date1?.getFullYear();
    let monthsDifference = date2?.getMonth() - date1?.getMonth();
  
    return yearsDifference * 12 + monthsDifference;
  }

  return (
      <div>
          <div>
              <h1 className="text-2xl font-bold">Mandates</h1>
              <LineGraph data={graphData} graphFor="home" cartesianGridType="dottedXonly"/>
              <div className="my-10">
                  <Table
                      tableTitle={"Mandate Activities from Businesses"}
                      hideSearch={false}
                      data={mandates?.results || []}
                      backendPaginated={true}
                      totalPages={mandates?.totalPages ?? 0}
                      isLoading={isLoading}
                      searchPlaceHolder="Search by reference..."
                      onSearchChange={(e)=>{
                        searchParams.set("search", e?.target?.value);
                        searchParams.set("page", 1);
                        setSearchParams(searchParams);
                      }}
                      slot={
                        <div>
                          <Filter 
                            options={
                                [].map((item) => ({
                                    label: item,
                                    value: item
                                }))
                            } 
                          />
                        </div>
                      }
                      theadClass="font-semibold text-[#667085] text-xs"
                      tdataClass="py-2.5 pl-[10px] text-sm"
                      rows={[
                          {
                              header:'Business Name',
                              view: (item) => (
                                  <span>
                                      {item?.businessId?.businessName}
                                  </span>
                              ),
                          },
                          {
                              header:'Amount Due',
                              view: (item) => (
                                  <span>
                                      {currencyFormatter(item?.totalAmountDue||0)}
                                  </span>
                              ),
                          },
                          {
                              header:'Duration',
                              view: (item) => (
                                  <span>{getMonthsBetweenDates(item?.startDate, item?.endDate)} month(s)</span>
                              ),
                          },
                          {
                              header:'Start Date',
                              view: (item) => (
                                  <span>{dateFormatter(item?.startDate, false)}</span>
                              ),
                          },
                          {
                              header:'End Date',
                              view: (item) => (
                                  <span>{dateFormatter(item?.endDate, false)}</span>
                              ),
                          },
                          {
                              header:'Collection Mode',
                              view: (item) => (
                                  <span className="capitalize">{item?.collectionMode}</span>
                              ),
                          },
                          {
                              header:'Status',
                              view: (item) => (
                                <div className="flex items-center text-sm pl-2.5">
                                    <div 
                                        className={clsx(
                                            "w-2.5 h-2.5 rounded-full",
                                            item?.status==="pending" ? "bg-[#FEC84B]" : item?.status==="completed"||item?.status==="active" ? "bg-[#2BBD35]" : "bg-[#F04438]"
                                        )}
                                    />
                                    <p 
                                        className={clsx(
                                            "ml-1.5 font-bold",
                                            item?.status==="pending" ? "text-[#FEC84B]" : item?.status==="completed"||item?.status==="active" ? "text-[#2BBD35]" : "text-[#F04438]"
                                        )}
                                    >{item?.status}</p>
                                </div>
                              ),
                          },
                          {
                            header: "",
                            view: (item) => (
                              <div>
                                <img 
                                  src={viewmore_dropdown} 
                                  alt="" 
                                  onClick={()=>{
                                    if(!!dropDownView){
                                      setDropDownView(null)
                                    } else {
                                      setDropDownView(item)
                                    }
                                  }}
                                  className={clsx(
                                    "w-8 cursor-pointer transition-all",
                                    item===dropDownView ? "rotate-180":"rotate-0",
                                  )}
                                />
                                {
                                  item===dropDownView &&
                                  <div className="absolute w-full left-0 z-10">
                                    <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px]">
                                      <div className="grid grid-cols-5 gap-[20px]">
                                        {
                                          [
                                            {
                                              title: "Consent ID",
                                              value: item?.consents?.[0]?._id||item?.consents?.[0]||"---",
                                            },
                                            {
                                              title: "eMandate Reference",
                                              value: item?.reference||"---",
                                            },
                                            {
                                              title: "Product Name",
                                              value: "---",
                                            },
                                            {
                                              title: "Mandate Type",
                                              value: "---",
                                            },
                                            {
                                              title: "Advanced Notice Sent",
                                              value: "True",
                                            },
                                            {
                                              title: "Collection Mode",
                                              value: item?.collectionMode||"---",
                                            },
                                            {
                                              title: "BVN",
                                              value: item?.bvn||"---",
                                            },
                                            {
                                              title: "Mandate Status",
                                              value: item?.status||"---",
                                            },
                                            {
                                              title: "Credit Insurance Enabled",
                                              value: item?.creditInsurance ? "True":"False",
                                            },
                                            {
                                              title: "Account Name",
                                              value: "---",
                                            },
                                            {
                                              title: "Payer's Address",
                                              value: "---",
                                            },
                                            {
                                              title: "Payer's Bank",
                                              value: "---",
                                            },
                                            {
                                              title: "Account Number",
                                              value: "---",
                                            },
                                            {
                                              title: "Payment Type",
                                              value: "---",
                                            },
                                            {
                                              title: "Total Mandate Amount",
                                              value: currencyFormatter(item?.totalAmountDue||0),
                                            },
                                            {
                                              title: "Total Outstanding",
                                              value: "---",
                                            },
                                            {
                                              title: "Total Debit",
                                              value: "---",
                                            },
                                            {
                                              title: "Duration",
                                              value: "---",
                                            },
                                            {
                                              title: "Start Date",
                                              value: dateFormatter(item?.startDate, false),
                                            },
                                            {
                                              title: "End Date",
                                              value: dateFormatter(item?.endDate, false),
                                            },
                                          ].map((item, idx)=>(
                                            <div key={idx} className="text-[#667085]">
                                              <p className="font-medium">{item?.title}</p>
                                              <p className="font-bold">{item?.value}</p>
                                            </div>
                                          ))
                                        }
                                      </div>
                                      <p className="text-base font-bold mt-6 mb-2.5">Creditor’s Beneficiary Details</p>
                                      <div className="grid grid-cols-4 gap-[20px]">
                                      {
                                          [
                                            {
                                              title: "Account Name",
                                              value: "---",
                                            },
                                            {
                                              title: "Account Number",
                                              value: "---",
                                            },
                                            {
                                              title: "Bank",
                                              value: "---",
                                            },
                                            {
                                              title: "Phone Number",
                                              value: "---",
                                            },
                                          ].map((item, idx)=>(
                                            <div key={idx} className="text-[#667085]">
                                              <p className="font-medium">{item?.title}</p>
                                              <p className="font-bold">{item?.value}</p>
                                            </div>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            )
                          },
                      ]}
                  />
              </div>
          </div>
      </div>
  )
}