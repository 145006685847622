import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
} from "recharts";
import { months } from 'utils/constants';
import { currencyFormatter } from "utils/helpers";


export default function OverviewBarChart({ 
    title, subtitle, data, yAxisName,
    removeFirst=false,
    currencyFormat=false,
 }) {
    var today = new Date();
    var d;
    var month = [];
  
    for(var i = 12; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month.push(months[d.getMonth()]);
    }

    const formattedData = data?.slice(removeFirst?1:0)?.map((item, idx)=>({
        name: month[idx]?.substring(0, 3),
        [`${yAxisName}`]: item ?? 0,
    }))
    
    return (
        <div className="bg-white rounded-md p-[20px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]">
            <h3 className="font-bold text-[21px] leading-[27px]">{title}</h3>
            <p className="text-sm">{subtitle}</p>
            <div className="mt-7">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={formattedData}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 45,
                            bottom: 0,
                        }}
                        padding={{
                            top: 40,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            horizontal={true}
                            vertical={false}
                        />
                        <XAxis dataKey="name" tick="#818181" />
                        <YAxis  tick="#818181" tickFormatter={(value)=>`${currencyFormat ? currencyFormatter(value, "NGN", 0, true):value}`} />
                        <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
                            itemStyle={{color:"#0046E6"}} 
                            formatter={(value) => new Intl.NumberFormat('en-NG', {
                            style: 'currency',
                            currency: 'NGN',
                            maximumFractionDigits: 2, 
                            }).format(value)} 
                        />
                        <Legend />
                        <Bar 
                            dataKey={yAxisName}
                            barSize={40} 
                            fill="#0046E6"
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}