// import Box from "../../Box";
// import Loader from "../../shared/Loader";
import { useState } from "react";
import apiIcon from '../../../images/svg/box/api/api.svg';
import greenGraphIcon from '../../../images/svg/box/graph.svg';
// import redGraphIcon from '../../../images/svg/box/graphRed.svg';

const dummyApiData = {
    "Cumulative": [
        { title: 'Total API Calls', value: '4567', lastMonth: 4.07, icon: apiIcon },
        { title: 'Total Billable API Calls', value: '4567', lastMonth: 4.07, icon: apiIcon },
    ],
    "Credit Insight": [
        { title: 'First Central - Summary', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FC' },
        { title: 'First Central - Full Report', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FC' },
        { title: 'Credit Advanced', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CA' },
        { title: 'Credit Registry - Full Report', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CR' },
        { title: 'Credit Registry - Full Summary', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CR' },
        { title: 'First Central - iScore', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FC' },
        { title: 'Credit CRC - Full Report', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'C' },
        { title: 'Credit CRC - Fico Score', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'C' },
        { title: 'First Central - SME', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'C' },
        { title: 'Credit CRC', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'C' },
        { title: 'Credit SME', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'PV' },
        { title: 'Credit Premium', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CP' },
    ],
    "Income Insight": [
        { title: 'PDF Statement Upload', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'PS' },
        { title: 'Open Banking', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'OB' },
    ],
    "Identity Insight": [
        { title: 'BVN Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'BV' },
        { title: 'NIN Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'NV' },
        { title: 'Passport Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'PV' },
        { title: 'Radar BVN', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'RV' },
        { title: 'Radar Phone Number', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'RN' },
        { title: 'Voters Card', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'VC' },
        { title: 'CAC Number Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CV' },
        { title: 'International Passport', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'IP' },
        { title: 'Account Number Basic Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'AV' },
        { title: 'Account Number Verification', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'AV' },
    ],
    "Float": [
        { title: 'Float Request', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FR' },
        { title: 'Float Principal Installment', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FP' },
        { title: 'Float Interest Installment', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FI' },
        { title: 'Float Default Fee', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'FD' },
    ],
    "RecovaPRO": [
        { title: 'Balance Enquiry', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'BE' },
        { title: 'Mandate Creation', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'MC' },
        { title: 'Collections Fee', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'CF' },
    ],
    "Others": [
        { title: 'VAT', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'VT' },
        { title: 'Stamp Duty Fee', total: '4567', billable: '4567', lastMonth: 4.07, icon: 'SD' },
    ]
};

export default function ApiCallTypes({ isLoading, boxData, queryVal }) {

    const headerNames = [
        "Cumulative",
        "Credit Insight",
        "Income Insight",
        "Identity Insight",
        "Float",
        "RecovaPRO",
        "Others"
    ];

    const [selectedHeader, setSelectedHeader] = useState(headerNames[0]);

    function handleSelectHeader(headerName) {
        setSelectedHeader(headerName);
    }

    return (
        <div>
            <ul className="list-none p-0 my-4 flex text-ee-grey-2 font-bold">
                {headerNames.map((item, index) => (
                    <li key={index} className="flex-1">
                        <button onClick={() => handleSelectHeader(item)}
                            className={`w-full p-2 cursor-pointer ${selectedHeader === item ? "border-b-4 border-ee-blue-3 bg-ee-blue-1" : ""
                                }`}>{item}</button>
                    </li>
                ))}
            </ul>
            <div>
                {
                    // isLoading ?
                    //     <Loader /> :
                    <DataBox headerName={selectedHeader} boxData={boxData} queryVal={queryVal} />
                }
            </div>
        </div>
    )
}

function DataBox({ headerName, boxData, queryVal }) {
    const data = dummyApiData[headerName];

    return (
        <ul className="flex gap-4 text-sm flex-wrap">
            {data.map((item) => <Card item={item} headerName={headerName} />)}
        </ul>
    );
}


function Card({ item, headerName }) {
    if (headerName === 'Cumulative')
        return (
            <li className="flex items-center gap-2 w-[250px] p-4 rounded-lg shadow-[2px_2px_20px_#eee]">
                <div className="flex items-center justify-center w-12 h-12 bg-ee-grey-10 rounded-full p-2">
                    <img src={item.icon} alt="api icon" width={30} />
                </div>
                <div>
                    <p className="font-bold text-lg text-ee-black-1">{item.value}</p>
                    <p className="text-ee-grey-1">{item.title}</p>
                    <p className="flex justify-between min-w-[166px] mt-2">
                        <span className="flex text-ee-green-3 gap-1"><img src={greenGraphIcon} alt="positive" /> <span>{item.lastMonth}%</span></span>
                        <span className="text-ee-grey-11">Last month</span>
                    </p>
                </div>
            </li>
        );

    return (
        <li key={headerName} className="flex items-center gap-2 w-[250px] p-4 rounded-lg shadow-[2px_2px_20px_#eee]">
            <div className="flex items-center justify-center w-12 h-12 bg-ee-grey-10 rounded-full text-ee-blue-4 font-semibold text-3xl p-2">
                {item.icon}
            </div>
            <div>
                <p className="text-ee-grey-1 font-semibold">{item.title}</p>
                <p className="flex justify-between min-w-[166px]"><span className="text-ee-grey-1">Total</span><span className="font-bold text-ee-black-1">{item.total}</span></p>
                <p className="flex justify-between min-w-[166px]"><span className="text-ee-grey-1">Billable</span><span className="font-bold text-ee-black-1">{item.billable}</span></p>
                <p className="flex justify-between min-w-[166px] mt-2">
                    <span className="flex text-ee-green-3"><img src={greenGraphIcon} alt="positive" /> <span>{item.lastMonth}%</span></span>
                    <span className="text-ee-grey-11">Last month</span>
                </p>
            </div>
        </li>
    );
}
