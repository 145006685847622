import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { apiStats } from '../../../lib/auth';
import ApiCallTypes from './ApiTypes';
import SelectInput from '../../../components/Form/SelectInput';
import Table from '../../../components/Table/general.js';
import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { CSVLink } from 'react-csv';
import download from '../../../images/svg/download.svg';
import Loader from '../../../components/shared/Loader';

const Apis = ({ setDetailName }) => {
    const [query, setQuery] = useState('this_month')
    const [queryVal, setQueryVal] = useState('This month')
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [customStartDate, setCustomStartDate] = useState("");
    const [customEndDate, setCustomEndDatee] = useState("");
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const handleClose = () => setShow(false);

    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

    const [boxData, setBoxData] = useState({
        boxFor: "apis",
        item: []
    });

    const [tableData, setTableData] = useState({
        tableFor: "apis",
        headings: ["Service Endpoint", "Action", "Business", "TOTAL COST", "WHEN", "STATUS"],
        items: []
    });

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    useEffect(() => {
        setIsLoading(true)
        setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0, 3)} ${new Date(endDateee)?.getFullYear()}`)
        setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0, 3)} ${new Date(endDateee)?.getFullYear()}`)

        apiStats(query)
            .then(response => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    setBoxData({
                        ...boxData,
                        item: res.data
                    })
                    setFilteredData(res?.data?.totalApiData);
                    setIsLoading(false)
                } else {
                    toast.error("Something went wrong, please try again!!!")
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message);
                    setIsLoading(false)
                } else {
                    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    setIsLoading(false)
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, startDateee, endDateee])

    useEffect(() => {

        const filtered = filteredData?.filter((item) =>
            item?.service?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            item?.action?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )
        setTableData({
            ...tableData,
            items: filtered
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, filteredData])

    const handleSelectChange = (name, val) => {
        if
            (val === 'All') {
            setQuery('all')
            setQueryVal('all')
        } else if
            (val === 'Last 7 days') {
            setQuery('last_seven_days')
            setQueryVal('Last 7 days')
        } else if (val === 'This Month') {
            setQuery('this_month')
            setQueryVal('This Month')
        } else if (val === 'Last Month') {
            setQuery('last_month')
            setQueryVal('Last Month')
        } else if (val === 'Last 3 Months') {
            setQuery('last_three_months')
            setQueryVal('Last 3 Months')
        } else if (val === 'Last 6 Months') {
            setQuery('last_six_months')
            setQueryVal('Last 6 Months')
        } else if (val === 'Last 12 Months') {
            setQuery('last_twelve_months')
            setQueryVal('Last 12 Months')
        } else if (val === 'Custom') {
            // setQuery('custom')
            setQueryVal('Custom')
            setShow(true)
        }
    }

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <div className='home_main'>
            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                        setStartDateee(formatPickerDate(item.selection.startDate))
                        setEndDateee(formatPickerDate(item.selection.endDate))
                        setState([item.selection])
                    }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button
                    style={{
                        color: "white",
                        padding: "7px 25px",
                        textAlign: "center",
                        border: "1px solid #0046E6",
                        background: "#0046E6"
                    }}
                    onClick={() => {
                        handleClose();
                        setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                        setQueryVal(`${customStartDate} - ${customEndDate}`)
                    }}
                >Proceed</button>
            </Modal>

            <div className='w-[150px] float-right -mt-[70px]'>
                <SelectInput
                    placeholder={"This Month"}
                    options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                    selectChange={(item) => handleSelectChange("Query", item)}
                />
            </div>

            <ApiCallTypes isLoading={isLoading} boxData={boxData} queryVal={queryVal} />

            <div className='home_main-tableHeader' >
                <div className="table_top" style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <h4>API Calls</h4>
                    </div>
                    <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                        <input placeholder="Search api calls" onChange={handleChange} className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                        />
                        <img src={search} alt="search" />
                    </div>
                </div>
            </div>
            {
                isLoading ?
                    <Loader /> :
                    <Table
                        data={tableData}
                    />
            }
            <div className='home_main-csv' >
                {
                    tableData.items.length > 0 &&
                    <CSVLink data={tableData.items} filename={`apis.csv`} >
                        <img src={download} alt="download" />CSV
                    </CSVLink>
                }
            </div>
        </div>
    )
}

export default Apis