import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";


function LineChartSuper({ data }) {
  const formatNaira = (value) => `₦${value.toLocaleString()}`
  const formatToString = (value) => `${value.toLocaleString()}`
  
  return (

    <>

    {
      data?.graphFor === "API_Calls" ? (
      <div style={{ marginLeft: "-7.7px" }}>
      <div style={{ height: "6px" }}></div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data?.item}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          padding={{
            top: 40,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <XAxis dataKey="name" tick="#818181" />
          <YAxis  tick="#818181" tickFormatter={formatToString} />
          <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
            itemStyle={{color:"#0046E6"}} 
            formatter={(value) => new Intl.NumberFormat('en').format(value)}
          />
          <Legend />
          <Bar 
            dataKey={data?.graphFor}
            barSize={40} 
            fill="#0046E6"
            // label={renderCustomBarLabel}
            />
          
        </BarChart>
      </ResponsiveContainer>
    </div>
    ) :
      
      data?.graphFor === "Amount_Spent" ? (
      <div style={{ marginLeft: "-7.7px" }}>
      <div style={{ height: "6px" }}></div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={data?.item}
          margin={{
            top: 0,
            right: 0,
            left: 45,
            bottom: 0,
          }}
          padding={{
            top: 40,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <XAxis dataKey="name" tick="#818181" />
          <YAxis  tick="#818181" tickFormatter={formatNaira}  />
          <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
            itemStyle={{color:"#0046E6"}} 
            formatter={(value) => new Intl.NumberFormat('en-NG', {
                  style: 'currency',
                  currency: 'NGN',
                  //minimumFractionDigits: 0, 
                  maximumFractionDigits: 2, 
                }).format(value)} 
          />
          <Legend />
          <Bar 
            dataKey={data?.graphFor}
            barSize={40} 
            fill="#0046E6"
            // label={renderCustomBarLabel}
            />
          
        </BarChart>
      </ResponsiveContainer>
    </div>
    ) :
      
    data?.graphFor === "Customers Onboarded" ? (
    <div style={{ marginLeft: "-7.7px" }}>
    <div style={{ height: "6px" }}></div>
    <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data?.item}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          padding={{
            top: 40,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <XAxis dataKey="name" tick="#818181" />
          <YAxis  tick="#818181" tickFormatter={formatToString} />
          <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
            itemStyle={{color:"#0046E6"}} 
            formatter={(value) => new Intl.NumberFormat('en').format(value)}
          />
          <Legend />
          <Bar 
            dataKey="value"
            barSize={40} 
            fill="#0046E6"
            name="Customers Onboarded"
          />
          
        </BarChart>
    </ResponsiveContainer>
  </div>
  ) :

    data?.graphFor ===  'Amount_Funded' ? (
      <div>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data?.item}
              margin={{
                top: 0,
                right: 0,
                left: 45,
                bottom: 0,
              }}
              padding={{
                top: 40,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
              />
              <XAxis dataKey="name" tick="#818181" />
              <YAxis  tick="#818181" tickFormatter={formatNaira} />
              <Tooltip contentStyle={{backgroundColor:"#fff", color: '#0046E6'}} 
                itemStyle={{color:"#0046E6"}} 
                formatter={(value) => new Intl.NumberFormat('en-NG', {
                  style: 'currency',
                  currency: 'NGN',
                  //minimumFractionDigits: 0, 
                  maximumFractionDigits: 2, 
                }).format(value)} 
              />
              {/* <Legend /> */}
              <Bar 
                dataKey={data?.graphFor}
                barSize={40} 
                fill="#0046E6"
                // label={renderCustomBarLabel}
                />
              
            </BarChart>
          </ResponsiveContainer>
        </div>
    ): null
    }

    

    </>
  );
}

export default LineChartSuper;
