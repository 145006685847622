import LineGraph from "./LineGraph";
import Filter from "components/shared/Filter";
import { currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import Table from "components/Table";
import Status from "components/Status";
import viewmore_dropdown from "images/svg/recova/viewmore_dropdown.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { RecovaService } from "services/recova.service";


export default function CollectionSection () {
    const [dropDownView, setDropDownView] = useState(null)
    const [path, setPath] = useState("manual")
    const [graphData, setGraphData] = useState([])
    const [data, setData] = useState(null)

    useEffect(()=>{
        if(path==="manual"){
            RecovaService.getManualCollectionActivity()
                .then((response) => response.data)
                .then((res) => {
                    setData(res.data)
                    const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
                      daysRange,
                      count
                    }));
                    setGraphData(formattedGraphData)
                })
                .catch((err) => {
                    handleRequestErrors(err)
                })
        } else {
            RecovaService.getAutoCollectionActivity()
                .then((response) => response.data)
                .then((res) => {
                    setData(res.data)
                    const formattedGraphData = Object.entries(res.data.graphData).map(([daysRange, count]) => ({
                      daysRange,
                      count
                    }));
                    setGraphData(formattedGraphData)
                })
                .catch((err) => {
                    handleRequestErrors(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])


    return (
        <div>
            <div>
                <h1 className="text-2xl font-bold">Collections</h1>
                <div className="text-base text-[#7E7E7E] my-7 font-medium flex items-center gap-x-6">
                    {
                        ["manual", "automated"].map((item, idx)=>(
                            <p 
                                className={clsx("pb-1 capitalize", path===item && "border-b-[2px] border-b-ee-primary")}
                                onClick={()=>setPath(item)}
                            >
                                {item}
                            </p>
                        ))
                    }
                </div>
                <div className="flex justify-center items-center gap-x-10 mb-8">
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#2F49D1] bg-opacity-10">
                            <ArrowIcon color="#2F49D1"/>
                        </div>
                        <div>
                            <p className="text-base font-bold">{data?.noOfCompletedCollections || 0}</p>
                            <p className="text-base font-bold">{currencyFormatter(data?.valueOfCompletedCollections || 0)}</p>
                            <p className="text--ee-grey-1">Completed Collections</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#D92D20] bg-opacity-10">
                            <ArrowIcon color="#D92D20"/>
                        </div>
                        <div>
                            <p className="text-base font-bold">{data?.noOfFailedCollections || 0}</p>
                            <p className="text-base font-bold">{currencyFormatter(data?.valueOfFailedCollections || 0)}</p>
                            <p className="text--ee-grey-1">Failed Collections</p>
                        </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                        <div className="w-[50px] h-[60px] rounded-full flex items-center justify-center bg-[#2F49D1] bg-opacity-10">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 19.25C18.3985 19.25 19.9375 17.711 19.9375 15.8125C19.9375 13.914 18.3985 12.375 16.5 12.375C14.6015 12.375 13.0625 13.914 13.0625 15.8125C13.0625 17.711 14.6015 19.25 16.5 19.25Z" stroke="#0046E6" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 14.4375V15.8125L15.4688 16.8438M19.9375 2.0625L2.0625 10.3125L10.3125 12.0312M19.9375 2.0625L10.3125 12.0312M19.9375 2.0625L16.9297 9.88281M10.3125 12.0312L10.7422 14.0078" stroke="#0046E6" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div>
                            <p className="text-base font-bold">0</p>
                            <p className="text-base font-bold">{currencyFormatter(0)}</p>
                            <p className="text--ee-grey-1">Upcoming Collections</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <LineGraph data={graphData} graphFor="payout" cartesianGridType="dottedXonly"/>
                </div>
                <div className="my-10">
                    <Table
                        tableTitle={"Collection Activities"}
                        hideSearch={false}
                        data={data?.collectionActivities||[]}
                        searchPlaceHolder="Search Business Name..."
                        slot={
                            <div>
                            <Filter 
                                options={
                                    ["Value"].map((item) => ({
                                        label: item,
                                        value: item
                                    }))
                                } 
                            />
                            </div>
                        }
                        theadClass="font-semibold text-[#667085] text-xs"
                        tdataClass="py-2.5 pl-[10px] text-sm"
                        rows={[
                            {
                                header:'Business Name',
                                view: (item) => (
                                    <span>
                                        {item.businessId.businessName}
                                    </span>
                                ),
                            },
                            {
                                header:'Account Name',
                                view: (item) => (
                                    <span>
                                        {item.accountName||"--"}
                                    </span>
                                ),
                            },
                            {
                                header:'Amount Triggered',
                                view: (item) => (
                                    <span>
                                        {currencyFormatter(item.totalAmount || 0)}
                                    </span>
                                ),
                            },
                            {
                                header:'Amount Collected',
                                view: (item) => (
                                    <span>
                                        {currencyFormatter(item.amountCollected||0)}
                                    </span>
                                ),
                            },
                            {
                                header:'Date of Collection',
                                view: (item) => (
                                    <span>
                                        {
                                            item.status==="success" ? dateFormatter(item.updatedAt, true)
                                            : "Not yet collected"
                                        }
                                    </span>
                                ),
                            },
                            {
                                header:'Mode of Collection',
                                view: (item) => (
                                    <span className="capitalize">{path}</span>
                                ),
                            },
                            {
                                header:'Status',
                                view: (item) => (
                                    <span>
                                        <Status
                                            type={
                                                item.status==="success"?"success"
                                                : item.status==="failed"?"fail"
                                                : "neutral"
                                            }
                                            text={item.status}
                                        />
                                    </span>
                                ),
                            },
                            {
                                header: "",
                                view: (item) => (
                                <div>
                                    <img 
                                    src={viewmore_dropdown} 
                                    alt="" 
                                    onClick={()=>{
                                        if(!!dropDownView){
                                        setDropDownView(null)
                                        } else {
                                        setDropDownView(item)
                                        }
                                    }}
                                    className={clsx(
                                        "w-8 cursor-pointer transition-all",
                                        item===dropDownView ? "rotate-180":"rotate-0",
                                    )}
                                    />
                                    {
                                    item===dropDownView &&
                                    <div className="absolute w-full left-0 z-10">
                                        <div className="shadow-md bg-white rounded mx-6 my-[10px] border-[1px] text-sm border-[#0349FD] p-[20px] grid grid-cols-4 gap-[20px]">
                                            <div>
                                                <p className="text-[#667085]">Name of Customer</p>
                                                <p>{item.accountName||"--"}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Amount Triggered</p>
                                                <p>{currencyFormatter(item.totalAmount || 0)}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Amount Collected</p>
                                                <p>{currencyFormatter(item.amountCollected||0)}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Date of Collection</p>
                                                <p>
                                                    {
                                                        item.status==="success" ? dateFormatter(item.updatedAt, true)
                                                        : "Not yet collected"
                                                    }
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Mode of Collection</p>
                                                <p>{path}</p>
                                            </div>
                                            {/* <div>
                                                <p className="text-[#667085]">Advanced Notice Sent</p>
                                                <p>{path}</p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Date of Notice</p>
                                                <p>{path}</p>
                                            </div> */}
                                            <div>
                                                <p className="text-[#667085]">Account Number</p>
                                                <p>{item.accountNumber}</p>
                                            </div>
                                            {/* <div>
                                                <p className="text-[#667085]">Bank</p>
                                                <p>Value</p>
                                            </div>
                                            <div>
                                                <p className="text-[#667085]">Phone Number</p>
                                                <p>Value</p>
                                            </div> */}
                                            <div>
                                                <p className="text-[#667085]">Status</p>
                                                <p>{item.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                )
                            },
                        ]}
                    />
                </div>
          </div>
        </div>
    )
}

const ArrowIcon = ({color}) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3119 20.6475H4.68813L0.931047 16.7876L0 17.7452L4.14294 22H17.8571L22 17.7452L21.0703 16.7876L17.3119 20.6475ZM11.0267 16.5635L5.29429 10.6763L6.22402 9.71875L10.3683 13.9749V0H11.6839V13.9749L15.8294 9.71876L16.7592 10.6763L11.0267 16.5635H11.0267Z" fill={color}/>
        </svg>
    )
}