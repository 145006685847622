import React, { useEffect, useState } from 'react'
import Table from '../../Table/general.js';
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import { Modal } from "react-bootstrap";
import search from '../../../images/svg/search.svg';
import { 
    createTopUp, 
    currencyConverter, 
    getAllTopUp, 
    rechargeWalletDev, 
    rechargeWalletProd, 
    verifyWalletDev, 
    verifyWalletProd 
} from '../../../lib/auth';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import download from '../../../images/svg/download.svg';
import Loader from '../../shared/Loader';


function ForeignExchange ({setHeader}) {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState({
        tableFor: "wallet-topUp",
        headings: ["ID", "Business Name", "Top-up Amount", "Previous Balance", "New Balance", "Top-up By", "Time (GMT)"],
        items: []
    });
    const [data, setData] = useState({
        businessId: '',
        environment: '',
        amount: ''
    })
    const [topUpData, setTopUpData] = useState({
        businessId: '',
        account_name: '',
        topUpAmount: '',
        previousBalance: '',
        newBalance: '',
        topUpBy: '',
        env: '',
        status: '',
    })
    const [currentBalance, setCurrentBalance] = useState('');

    const [query, setQuery] = useState('all')
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [idCheck, setIdCheck] = useState(false);

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    let adminName = ''

    try {
        adminName = JSON.parse(sessionStorage.getItem('adminName'));
    } catch (err) {
        //err
    }
    
    const handleClose = () => setShow(false);

    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var today = new Date();
    var d;
    var month = [];
    // let monthSlice = [];

    for(var i = 6; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(monthNames[d.getMonth()]);
    // monthSlice = mon
    console.log(month);
    }

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
      }
      

    const handleFormChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
      });
      useEffect(() => {
        currencyConverter(1000 )
        .then(response => console.log(response))
        .catch((err) => {
            console.log(err)
        });
      }, [])
      

    useEffect(()=>{
        setHeader();
        setIsLoading(true)
          getAllTopUp(query)
          .then((response) => response.data)
          .then((res) => {
              if (res.status === "success" ) {
                setFilteredData(res.data.reverse())
                setIsLoading(false)
              } else {
                  toast.error("Something went wrong, please try again!!!")
                  setIsLoading(false)
              }
          })
          .catch((err) => {
              console.log(err)
              console.log(err.response)
              toast.error(err.response.data.message);
              setIsLoading(false)
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      useEffect(() => {
        const filtered = filteredData?.filter((item) => 
        item?.businessId?.includes(searchTerm.toLowerCase()) 
        ) 
      console.log(filtered)
        setTableData({
          ...tableData,
          items: filtered
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [searchTerm, filteredData])

      const handleTopUpCheck = () => {
          if(data?.businessId === ""){
             toast.error("Choose an environment") 
          }else{
            if(data?.environment === 'Development'){
                verifyWalletDev(data?.businessId)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success ===true) {
                        toast.success("Check successful!")
                        setCurrentBalance(res?.data?.balance);
                        setIdCheck(true);
                        setTopUpData({
                            ...topUpData,
                            businessId: res?.data?.businessId,
                            account_name: res?.data?.account_name,
                            previousBalance: res?.data?.balance,
                            env: 'Development',
                        })
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    console.log(err.response)
                    if(err?.response?.data?.success === true ||
                        err?.response?.data?.message === "Wallet not found"){
                            toast.error("Incorrect business ID, please ensure you're on the right environment")
                        }
                });
              }else if(data?.environment === 'Production'){
                verifyWalletProd(data?.businessId)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success ===true) {
                        toast.success("Check successful!")
                        setCurrentBalance(res?.data?.balance)
                        setIdCheck(true)
                        setTopUpData({
                            ...topUpData,
                            businessId: res?.data?.businessId,
                            account_name: res?.data?.account_name,
                            previousBalance: res?.data?.balance,
                            env: 'Production',
                        })
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    console.log(err?.response?.data)
                    if(err?.response?.data?.success === true ||
                        err?.response?.data?.message === "Wallet not found"){
                            toast.error("Incorrect business ID, please ensure you're on the right environment")
                        }
                });
            }
          }
      }

      const handleTopUp = () => {
          let payload = {
              businessId: topUpData?.businessId,
              amount: data?.amount
          }
          console.log(payload)
          if(!data?.businessId || !data?.environment || !data?.amount){
             toast.error("Please fill all fields above") 
          }else if(!idCheck){
            toast.error("Please check businessID first") 
          }else {
            if(data?.environment === 'Development'){
                rechargeWalletDev(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success ===true) {
                    toast.success(res.message)
                       createTopUpRecord(res?.data)
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    console.log(err.response)
                    toast.error(err.response.data.message);
                });
              }else if(data?.environment === 'Production'){
                rechargeWalletProd(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success ===true) {
                       toast.success(res.message)
                       createTopUpRecord(res?.data)
                    } else {
                        toast.error("Something went wrong, please try again!!!")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    console.log(err.response)
                    toast.error(err.response.data.message);
                });
            }
          }
      }

      const createTopUpRecord = (item) => {
          let payload = {
            businessId: item?.businessId,
            account_name: item?.account_name,
            topUpAmount: data?.amount,
            previousBalance: currentBalance,
            newBalance: item?.balance,
            topUpBy: adminName,
            env: data?.environment,
          }
          createTopUp(payload)
          .then((response) => response.data)
            .then((res) => {
                if (res.status ==="success") {
                    window.location.reload()
                } else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {
                console.log(err)
                console.log(err.response)
            });
      }

      function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        return [year, month, day].join('-');
      }

    const CSVHeaders = [
        { label: "ID", key: "businessId" },
        { label: "Business Name", key: "account_name" },
        { label: "Top-up Amount", key: "topUpAmount" },
        { label: "Previous Balance", key: "previousBalance" },
        { label: "New Balance", key: "newBalance" },
        { label: "Top-up By", key: "topUpBy" },
        { label: "Time", key: "createdAt" },
    ]
    
    return(
        <div className='home_main'>

            <Modal show={show} onHide={handleClose}>
              <DateRange
                  editableDateInputs={true}
                  onChange={item => {
                          setStartDateee(formatPickerDate(item.selection.startDate))
                          setEndDateee(formatPickerDate(item.selection.endDate))
                          setState([item.selection])
                      }
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={state}
              />
              <button 
                  style={{
                      color:"white",
                      padding:"7px 25px",
                      textAlign:"center",
                      border:"1px solid #0046E6",
                      background:"#0046E6"
                  }} 
                  onClick={()=>{   
                          handleClose();
                          setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      }}
                  >Proceed</button>
          </Modal>
            <div className='home_wallet-topup-form'>
                <h4>FX Rate</h4>
                <div>
                    <label>Select Environment</label><br/>
                    <div style={{margin:'0 0 20px'}}>
                        <Select
                            placeholder={"Select Environment"}
                            onChange={(e)=>{
                                setData({
                                    ...data,
                                    environment: e.value
                                })
                            }}
                            options={[
                                { value: "Development", label: "Development" },
                                { value: "Production", label: "Production" }
                            ]}
                        /> 
                    </div>
                </div>
                <div>
                    <label>Enter Business ID</label><br/>
                    <div className='home_wallet-topup-form-withBtn'>
                        <input placeholder='Business ID' name="businessId" onChange={handleFormChange} />
                        <button 
                            type="button" 
                            className={data?.businessId === '' ? 'btnInactive' : 'btnActive'}
                            disabled={data?.businessId === ''}
                            onClick={handleTopUpCheck} >Check</button>
                    </div>
                    {
                        currentBalance &&
                        <p className='home_wallet-topup-form-text1'>Current Wallet Balance : {formatMoney.format(currentBalance)}</p>
                    }
                   
                </div>
             
                <div>
                    <label>Top-up Amount</label><br/>
                    <CurrencyFormat 
                        thousandSeparator={true} 
                        placeholder={'Input amount'}
                        prefix={'₦'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        onValueChange={(values) => {
                            setData({
                                ...data,
                                amount: values.value
                            })
                        }}
                    />
                </div>
                <button className='home_wallet-topup-form-button' onClick={handleTopUp}>Top Up Wallet</button>
            </div>
            <div className='home_wallet-topup-table'>
                <div className='home_main-tableHeader home_wallet-topup-table-header' >
                    <div className="table_top" style={{display:"flex", alignItems:"center"}}>
                        <div>
                            <h4>Wallet Top-Up History</h4>
                        </div>
                        <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                            <input placeholder="Search with Business ID" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                            />
                            <img src={search} alt="search" />
                        </div>
                    </div>
                </div>

                {
                    isLoading ?
                    <Loader /> :
                    <Table  
                        data={tableData} 
                    />
                }

                <div className='home_main-csv' style={{marginTop: tableData.items.length<11 ? '-30px':'-70px'}} >
                    { 
                        tableData.items.length > 0 &&
                        <CSVLink data={tableData.items} filename={"wallet-topUp.csv"} headers={CSVHeaders} >
                        <img src={download} alt="download" />CSV
                        </CSVLink>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForeignExchange