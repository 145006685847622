import clsx from "clsx"
import Table from "components/Table"
import { useEffect, useState } from "react"
import { BusinessService } from "services/business.service"

export default function TopBusinesses ({
    query,
    height="h-[510px]",
}) {
    const [topBusinessesData, setTopBusinessesData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getTopBusinesses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const getTopBusinesses = () => {
        setLoading(true)
        BusinessService.getTopBusinesses(query)
        .then((response) => response.data)
        .then((res) => {
            setTopBusinessesData(res.data??[])
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false)
        })
    }

    return(
        <div>
            <h3 className='text-[21px] mb-2.5 font-bold leading-[27px]'>Top Businesses</h3>
            <div 
                className={clsx(
                    'overflow-y-scroll',
                    height
                )}
            >
                <Table
                    loading={loading}
                    data={topBusinessesData}
                    theadClass="!font-bold py-3 text-[#667085] bg-[#F0F0F0] text-sm"
                    tdataClass="py-6 pl-[10px] text-sm"
                    trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                    hideTableTop={true}
                    hideOverallTableBorder={true}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    paginationContainer="hidden"
                    rows={[
                        {
                            header:'ID',
                            view: (item) => (
                                <div className='!w-[80px] text-ellipsis overflow-hidden'>
                                    <span>
                                        #{item?.businessId||"---"}
                                    </span>
                                </div>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <div className='flex items-center gap-x-1.5 max-w-[300px]'>
                                    <div
                                        className='w-8 h-8 rounded shrink-0 bg-no-repeat bg-center bg-cover'
                                        style={{
                                            backgroundImage: !!item?.businessLogo ? `url(${item?.businessLogo})` : "url('/profile-placeholder.svg')"
                                        }}
                                    />
                                    <span>{item?.businessName||"---"}</span>
                                </div>
                            ),
                        },
                        {
                            header:'Total API Calls',
                            view: (item) => (
                                <div className='w-[100px] h-8 bg-[#DCE7FF] flex items-center justify-center rounded-md text-[#3A36DB]'>
                                    <span>
                                        {item?.apicallsCount||"---"}
                                    </span>
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}