import React from "react";
import IdleUser from "./IdleUser";
import DeleteModal from "./DeleteModal";
import DeleteAdminModal from "./DeleteAdminModal";
import CustomRoles from "./CustomRoles";
import SendFloatOffer from "./SendFloatOffer";
import RejectFloatModal from "./RejectFloatModal";
import PendingFloatModal from "./PendingFloatModal";
import DisbursedFloatModal from "./DisbursedFloatModal";
import BusinessesModal from "../Dashboard/EmailBlast/components/BusinessesModal";

function Modal ({type, cancel, doAction}) {
 
    return(
        <div className="myModal">
            {type !== "final-modal" && type !== "share-your-data" && <div className="myModal_blurBg" ></div>}
            <div className= {"myModal_main"} >
                {
            
                    type==="idle-user" ? <IdleUser cancel={cancel} /> :
                    type==="delete" ? <DeleteModal cancel={cancel} doAction={doAction} /> :
                    type==="reject-float" ? <RejectFloatModal cancel={cancel} doAction={doAction} /> :
                    type==="pending-float" ? <PendingFloatModal cancel={cancel} doAction={doAction} /> :
                    type==="disbursed-float" ? <DisbursedFloatModal cancel={cancel} doAction={doAction} /> :
                    type==="delete-admin" ? <DeleteAdminModal cancel={cancel} doAction={doAction} /> :
                    type==="send-offer" ? <SendFloatOffer cancel={cancel} doAction={doAction} /> :
                    type==="businesses" ? <BusinessesModal cancel={cancel} doAction={doAction} /> :
                    type==="custom-role" ? <CustomRoles cancel={cancel} doAction={doAction} /> 
                    :<div></div>
                }
               
            </div>
        </div>
    )
}

export default Modal;