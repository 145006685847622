import clsx from "clsx";
import Button from "components/shared/Button";
import {Modal} from "react-bootstrap";


function WarningModal ({
    show, 
    handleAction, 
    handleClose, 
    type, 
    title, 
    text, 
    isLoading=false,
}) {
    // type - warning | success

    return(
        <Modal show={show} size="md" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="p-[20px]">
                <h4 className="text-xl font-semibold text-ee-black-3 capitalize">{title}</h4>
                <p className="text-ee-black-4 py-2.5">{text}</p>
                <div className="flex justify-between">
                    <button className="border-[1px] border-ee-grey-3 text-black font-semibold px-6 py-2 mr-5 rounded-[6px]" onClick={handleClose}>Cancel</button>
                    {/* <button onClick={()=>handleAction()} className={clsx(
                            "border-[1px] text-white font-semibold px-6 py-2 mr-5 rounded-[6px] capitalize",
                            type==="warning" ? "bg-ee-red-3 border-ee-red-3":"bg-ee-green-3 border-ee-green-3"
                        )}
                    >
                        Proceed
                    </button> */}
                    <Button
                        text="Proceed"
                        isLoading={isLoading}
                        onClick={()=>handleAction()}
                        className={clsx(
                            "border-[1px] text-white font-semibold px-6 py-2 mr-5 rounded-[6px] capitalize",
                            type==="warning" ? "bg-ee-red-3 border-ee-red-3":"bg-ee-green-3 border-ee-green-3"
                        )}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default WarningModal;