import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import SelectInput from '../../../components/Form/SelectInput';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { months } from '../../../utils/constants';
import { BusinessService } from 'services/business.service';
import DataBox from 'components/shared/DataBox';
import { currencyFormatter, formatBigNumber } from 'utils/helpers';
import api from 'images/svg/box/api.svg'
import walletBlue from 'images/svg/box/walletBlue.svg'
import walletDarkBlue from 'images/svg/box/wallet/walletDarkBlue.svg'
import wallet from 'images/svg/box/wallet.svg'
import graph from 'images/svg/box/graph.svg'
import graphRed from 'images/svg/box/graphRed.svg'
import clsx from 'clsx';
import OverviewBarChart from './components/BarChart';
import { WalletService } from 'services/wallet.service';
import TopBusinesses from './components/TopBusinesses';
import useSingleState from 'hooks/useSingleState';


const Overview = ({setDetailName}) => {
  const [query, setQuery] = useState('this_month')
  const [queryVal, setQueryVal] = useState('This month')
  const [startDateee, setStartDateee] = useState("");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDatee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [show, setShow] = useState(false);
  const [fundingGraphData, setFundingGraphData] = useState([]);
  const [revenueGraphData, setRevenueGraphData] = useState([]);
  const [onboarded, setOnboarded] = useState([]);
  const [apiGraph, setApiGraph] = useState([]);
  const businessStats = useSingleState(null)
  const apiStats = useSingleState(null)
  const walletStats = useSingleState(null)
  const revenueStats = useSingleState(null)

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    setDetailName("");
  }, [setDetailName]);

  const handleClose = () => setShow(false);

  

  var today = new Date();
  var d;
  var month = [];
  // let monthSlice = [];

  for(var i = 12; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month.push(months[d.getMonth()]);
  }

  function formatPickerDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleSelectChange = (name, val) => {
    if
    (val=== 'Last 7 days'){
      setQuery('last_seven_days')
      setQueryVal('Last 7 days')
    }else if(val === 'This Month'){
      setQuery('this_month')
      setQueryVal('This Month')
    }else if(val === 'Last Month'){
      setQuery('last_month')
      setQueryVal('Last Month')
    }else if(val === 'Last 3 Months'){
      setQuery('last_three_months')
      setQueryVal('Last 3 Months')
    }else if(val === 'Last 6 Months'){
      setQuery('last_six_months')
      setQueryVal('Last 6 Months')
    }else if(val === 'Last 12 Months'){
      setQuery('last_twelve_months')
      setQueryVal('Last 12 Months')
    }else if(val === 'Custom'){
      setQuery('custom')
      setQueryVal('Custom')
      setShow(true)
    }
  }

  useEffect(() => {
    getWalletFunding()
    getRevenueActivity()
    getBusinessGraph()
    getApiActivityGraph()
    getBusinessStats()
    getAccountStats()
    getRevenueStats()
    getApiStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const getBusinessStats = () => {
    BusinessService.getBusinessData(query)
      .then((response) => response.data)
      .then((res) => {
        businessStats.set(res.data)
      })
      .catch((err) => {})
  }

  const getAccountStats = () => {
    BusinessService.getAccountData(query)
      .then((response) => response.data)
      .then((res) => {
        walletStats.set(res.data)
      })
      .catch((err) => {})
  }

  const getApiStats = () => {
    BusinessService.getApiData(query)
      .then((response) => response.data)
      .then((res) => {
        apiStats.set(res.data)
      })
      .catch((err) => {})
  }

  const getRevenueStats = () => {
    BusinessService.getRevenueData(query)
      .then((response) => response.data)
      .then((res) => {
        revenueStats.set(res.data)
      })
      .catch((err) => {})
  }

  const getWalletFunding = () => {
    WalletService.prodIncomeActivity(query)
      .then((response) => response.data)
      .then((res) => {
        setFundingGraphData(res.data)
      })
      .catch((err) => {})
  }

  const getRevenueActivity = () => {
    BusinessService.revenueActivity()
      .then((response) => response.data)
      .then((res) => {
        setRevenueGraphData(res.data)
      })
      .catch((err) => {})
  }

  const getBusinessGraph = () => {
    BusinessService.customerOnboarding()
      .then((response) => response.data)
      .then((res) => {
        setOnboarded(res.data)
      })
      .catch((err) => {})
  }

  const getApiActivityGraph = () => {
    BusinessService.apiActivity()
      .then((response) => response.data)
      .then((res) => {
        setApiGraph(res.data)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, startDateee, endDateee ])

  return (
    <div className='mt-10'>
      <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                      setStartDateee(formatPickerDate(item.selection.startDate))
                      setEndDateee(formatPickerDate(item.selection.endDate))
                      setState([item.selection])
                  }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      setQueryVal(`${customStartDate} - ${customEndDate}`)
                  }}
              >Proceed</button>
      </Modal>  
      <div className='w-[150px] float-right -mt-[70px]'>
        <SelectInput 
            placeholder={"This Month"}
            options={["Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
            selectChange={(item)=>handleSelectChange("Query", item)}
        />
      </div>
      <div className='grid grid-cols-5 gap-x-10 box-border'>
        <div className='col-span-3'>
          <div className='grid grid-cols-2 gap-y-8 gap-x-[20px] box-border'>
            {
              [
                {
                  title: "Total Approved Businesses",
                  value: formatBigNumber(businessStats?.get?.approvedBusinesses || 0, true),
                  icon: walletBlue,
                },
                {
                  title: "Total API Calls",
                  value: formatBigNumber(apiStats.get?.totalApiCalls||0, true),
                  icon: api,
                },
                {
                  title: "Total Wallet Funding",
                  value: currencyFormatter(walletStats.get?.totalWalletFunding||0),
                  icon: wallet,
                },
                {
                  title: "Total Revenue",
                  value: currencyFormatter(revenueStats.get?.totalRevenue||0),
                  icon: walletDarkBlue,
                },
                // the ones below are affected by the filter
                {
                  title: "Number of businesses",
                  value: formatBigNumber(businessStats?.get?.numberOfBusinesses||0, true),
                  icon: walletBlue,
                  percentageDifference: businessStats?.get?.businessPercent,
                },
                {
                  title: "Number of API calls",
                  value: formatBigNumber(apiStats.get?.numberOfApiCalls||0, true),
                  icon: api,
                  percentageDifference: apiStats.get?.apiPercent,
                },
                {
                  title: "Value of wallet funded",
                  value: currencyFormatter(walletStats.get?.numberOfWalletFunding || 0),
                  icon: wallet,
                  percentageDifference: walletStats.get?.walletPercent,
                },
                {
                  title: "Value of revenue",
                  value: currencyFormatter(revenueStats.get?.numberOfRevenue || 0),
                  icon: walletDarkBlue,
                  percentageDifference: revenueStats.get?.revenuePercent,
                },
              ].map(item => (
                <div key={item.title}>
                  <DataBox 
                    title={item.title}
                    icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                    value={item.value}
                    className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                    slot={
                      !!item?.percentageDifference ?
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-0.5'>
                          <img src={item?.percentageDifference < 0 ? graphRed : graph} alt="icon" /> 
                          <span 
                            className={clsx(
                              "text-sm",
                              item?.percentageDifference < 0 ? 'text-[#FA582E]':"text-[#4BDE97]"
                            )}
                          >
                            {Math.abs(item?.percentageDifference||0)}
                          </span>
                        </div>
                        <p className='text-sm text-[#B9B9B9]'>{queryVal}</p>
                      </div>
                      : null
                    }
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className='col-span-2 bg-white rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
          <TopBusinesses query={query} />
        </div>
      </div>
      <div className='mt-10 mb-[60px] flex flex-col gap-10'>
        <OverviewBarChart  
          data={fundingGraphData}
          title="Wallet Funding"
          subtitle="Total Amount Funded"
          yAxisName="Amount Funded"
          removeFirst={true}
          currencyFormat={true}
        />
        <OverviewBarChart  
          data={revenueGraphData}
          title="Revenue"
          subtitle="Total Amount Spent"
          yAxisName="Amount Spent"
          removeFirst={true}
          currencyFormat={true}
        />
        <OverviewBarChart  
          data={onboarded}
          title="Businesses Onboarded"
          yAxisName="Onboarded"
          removeFirst={false}
          currencyFormat={false}
        />
        <OverviewBarChart  
          data={apiGraph}
          title="Business activity"
          subtitle="API trends"
          yAxisName="API Calls"
          removeFirst={true}
          currencyFormat={false}
        />
      </div>
    </div>
    
  )
}

export default Overview