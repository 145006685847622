import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import SelectInput from '../../../components/Form/SelectInput';
import Table from '../../../components/Table';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import TopBanks from './components/TopBanks';
import { formatBigNumber, handleRequestErrors } from 'utils/helpers';
import DataBox from 'components/shared/DataBox';
import bank from 'images/svg/box/bank.svg'
import { BankService } from 'services/bank.service';
import SearchField from 'components/shared/SearchField';
import dayjs from 'dayjs';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useSearchParams } from 'react-router-dom';

const Banks = ({setDetailName}) => {
    const [query, setQuery] = useState('all')
    const [data, setData] = useState(null)
    const [tableData, setTableData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        setDetailName("");
    }, [setDetailName]);

    const handleClose = () => setShow(false);

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    useEffect(() => {
        const filtered = tableData?.filter((item) => 
            item?.businessId?.businessName?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.institution?.name?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.name?.toLowerCase()?.includes(search.toLowerCase()) || 
            item?.accountNumber?.toLowerCase()?.includes(search.toLowerCase())
        )
        if(!!search){
            setTableData(filtered)
        } else {
            setTableData(data.totalAccountLinkedData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])
  
    useEffect(() => {
        getBankStat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    const getBankStat = () => {
        setIsLoading(true)
        BankService.bankStats(query)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    console.log("stats", res.data)
                    setData(res.data)
                    setTableData(res.data.totalAccountLinkedData.reverse())
                    setIsLoading(false)
                }    else {
                    toast.error("Something went wrong, please try again!!!")
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
    }

    const handleSelectChange = (name, val) => {
        if(val === 'All'){
        setQuery('all')
        }else if(val=== 'Last 7 days'){
        setQuery('last_seven_days')
        }else if(val === 'This Month'){
        setQuery('this_month')
        }else if(val === 'Last Month'){
        setQuery('last_month')
        }else if(val === 'Last 3 Months'){
        setQuery('last_three_months')
        }else if(val === 'Last 6 Months'){
        setQuery('last_six_months')
        }else if(val === 'Last 12 Months'){
        setQuery('last_twelve_months')
        }else if(val === 'Custom'){
        setQuery('custom')
        setShow(true)
        }
    }

    const csvConfig = mkConfig({ 
        useKeysAsHeaders: false,
        columnHeaders: [
            { displayLabel: "User Name", key: "name" },
            { displayLabel: "Bank Name", key: "institution.name" },
            { displayLabel: "Business Name", key: "businessId.businessName" },
            { displayLabel: "Account Number", key: "accountNumber" },
            { displayLabel: "Date", key: "updatedAt" },
        ],
        filename: "banks"
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                            setStartDateee(formatPickerDate(item.selection.startDate))
                            setEndDateee(formatPickerDate(item.selection.endDate))
                            setState([item.selection])
                        }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button 
                    style={{
                        color:"white",
                        padding:"7px 25px",
                        textAlign:"center",
                        border:"1px solid #0046E6",
                        background:"#0046E6"
                    }} 
                    onClick={()=>{   
                            handleClose();
                            setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                        }}
                    >Proceed</button>
            </Modal>
            <div className='w-[150px] float-right -mt-[70px]'>
                <SelectInput 
                    placeholder={"All"}
                    options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                    selectChange={(item)=>handleSelectChange("Query", item)}
                />
            </div>
            <div className='grid grid-cols-3 gap-x-10 box-border mt-7'>
                <div className='col-span-1'>
                    <div className='flex flex-col gap-y-[20px] box-border'>
                        {
                            [
                                {
                                    title: "Total Bank Account Linked as at today",
                                    value: formatBigNumber(data?.totalAccountLinkedToday || 0, true),
                                    icon: bank,
                                },
                                {
                                    title: "Bank Accounts Linked",
                                    value: formatBigNumber(data?.totalAccountLinked || 0, true),
                                    icon: bank,
                                },
                            ].map(item => (
                                <div key={item.title}>
                                    <DataBox 
                                        title={item.title}
                                        icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                                        value={item.value}
                                        className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='col-span-2 bg-white rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
                    <TopBanks query={query} />
                </div>
            </div>
            <div className='my-6'>
                <div className='flex justify-between items-center'>
                    <p className='text-xl font-bold'>Recent Bank Accounts Linked</p>
                    <SearchField
                        containerClass='ml-auto my-6'
                    />
                </div>
                <Table
                    loading={isLoading}
                    data={tableData || []}
                    theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                    tdataClass="py-6 pl-[10px] text-sm"
                    trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                    hideTableTop={true}
                    hideOverallTableBorder={true}
                    hideTableBodyBorder={true}
                    hideLines={true}
                    paginationContainer="!px-0"
                    showDownload={true}
                    onDownloadClick={()=>{
                        if(tableData.length>0){
                            const csv = generateCsv(csvConfig)(tableData);
                            download(csvConfig)(csv)
                        }
                    }}
                    rows={[
                        {
                            header:'Bank Name',
                            view: (item) => (
                                <span className='capitalize'>
                                    {item?.institution?.name||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'User Name',
                            view: (item) => (
                                <span>
                                    {item?.name||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Business Name',
                            view: (item) => (
                                <span>
                                    {item?.businessId?.businessName||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Account Number',
                            view: (item) => (
                                <span>
                                    {item?.accountNumber||"---"}
                                </span>
                            ),
                        },
                        {
                            header:'Date',
                            view: (item) => (
                                <span>
                                    {dayjs(item?.updatedAt).format("DD/MM/YYYY")}
                                </span>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default Banks