// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import OldPagination from "./OldPagination";
import deleteIcon from '../../images/svg/approvals/delete.svg'
import profile from "../../images/svg/profile-dummy.svg";
import see from "../../images/svg/see.svg";
import checkmark from "../../images/svg/float/checkmark.svg";
import prodIcon from "../../images/svg/approvals/prod-icon.svg";
import devIcon from "../../images/svg/approvals/dev-icon.svg";
import { Tooltip } from "react-tooltip";
import scheduledIcon from "../../images/svg/float/scheduled.svg";
import { currencyFormatter } from "utils/helpers";
import dayjs from "dayjs";


function Table ({data, doAction, doUpdate, handleDeleteChange, viewBusinessInfo, viewRequestInfo, binStatus}) {
   const [arr, setArr] = useState([])
    const [page, setPage] = useState(0);
    const [displayData, setDisplayData] = useState([]);

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
      });

    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }

    useEffect(()=>{        
        if(data?.items?.length > 0){
            setArr(data?.items)
            setDisplayData(paginate(arr, 10)); // 10 per page
        }
        else {
            setDisplayData([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.items, arr]);

    function paginate (arr, size) {
        return arr.reduce((acc, val, i) => {
          let idx = Math.floor(i / size)
          let page = acc[idx] || (acc[idx] = [])
          page.push(val)
      
          return acc
        }, [])
    }

    function changePage (name, num) {
        if(name==="prev"){
            if (num<0){console.log("end")}
            else {setPage(num)}
        } else if (name==="next") {
            if (num === displayData.length){console.log("end")}
            else {setPage(num)}
        } else{
            if (parseInt(num)<=0 || isNaN(parseInt(num)) || parseInt(num) > displayData.length){console.log("end")}
            else {setPage(parseInt(num)-1)}
        }
    }
   

    
    return(
        <div>
            <div className="table tweak">
                {
                    // change back to {displayData.length > 0 ? }
                    displayData.length > 0 ?
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    { data.headings.map((item,idx)=>(<th key={idx}>{item}</th>))}
                                </tr>
                            </thead>
                            { 
                                data.tableFor==="approval" ? 
                                    <tbody>
                                        {
                                            displayData?.[page]?.map((item, idx) =>(
                                                <tr key={idx} >
                                                    <td>{item?.businessName} </td>
                                                    <td>{item?.businessSector} </td>
                                                    <td>{item?.email} </td>
                                                    <td>{item?.phoneNumber} </td>
                                                    <td style={item?.isComplied === false ? {color: '#FB2C2C', fontWeight: '600'} : {color: "#47D800", fontWeight: '600'}}>{item?.isComplied === false ? "False" : "True"} </td>
                                                    <td>{dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                    <td>{item?.country} </td>

                                                {
                                                    item.isActive === "pending" ?
                                                    <td className="table_income-btn"  style={{width:'250px'}}>
                                                        <button className="see" onClick={()=>viewBusinessInfo(item?._id, 'approval')} > <img src={see} alt="see" /> </button>
                                                        <button className="view" onClick={()=>doAction(item?._id, 'approved', 'approval')}>Approve</button>
                                                        <button className="reload" onClick={()=>doAction(item?._id, 'declined', 'approval')}>Decline</button>
                                                    </td> :
                                                    item.isActive === "approved" ?
                                                    <td className="table_income-btn">
                                                        <button className="see" onClick={()=>viewBusinessInfo(item?._id, 'approval')} > <img src={see} alt="see" /> </button>
                                                        <button className="reload" onClick={()=>doAction(item?._id, 'suspended', 'approval')}>Suspend</button>
                                                    </td> :
                                                    item.isActive === "declined" ?
                                                    <td className="table_income-btn">
                                                        <button className="see" onClick={()=>viewBusinessInfo(item?._id, 'approval')} > <img src={see} alt="see" /> </button>
                                                        <button className="view" onClick={()=>doAction(item?._id, 'approved', 'approval')}>Approve</button>
                                                        <img style={{cursor: 'pointer'}}  onClick={()=>handleDeleteChange(item?._id)} src={deleteIcon} alt="delete" />
                                                    </td> :
                                                    item.isActive === "suspended" ?
                                                    <td className="table_income-btn">
                                                        <button className="see" onClick={()=>viewBusinessInfo(item?._id, 'approval')} > <img src={see} alt="see" /> </button>
                                                        <button className="view" onClick={()=>doAction(item?._id, 'approved', 'approval')}>Approve</button>
                                                        <img style={{cursor: 'pointer'}} onClick={()=>handleDeleteChange(item?._id)} src={deleteIcon} alt="delete" />
                                                    </td> : 
                                                    <td></td>
                                                }
                                                    
                                                    
                                                </tr>
                                            ))
                                        }
                                </tbody>
                                :
                                data.tableFor==="spectrum-approval" ? 
                                    <tbody>
                                        {
                                            displayData?.[page]?.map((item, idx) =>(
                                                <tr key={idx} >
                                                    <td>{item?.businessId?.businessName} </td>
                                                    <td>{item?.businessId?.email} </td>
                                                    <td>{item?.businessId?.phoneNumber} </td>
                                                    <td>{dayjs(item?.businessId?.updatedAt).format("DD/MM/YYYY hh:mm A")} </td>
                                                    <td>{item?.businessId?.country} </td>
                                                    {
                                                        <td className="table_income-btn"  style={{width:'250px', display:'flex', marginTop:'10px'}}>
                                                            <button className="see" onClick={()=>viewBusinessInfo(item?.businessId?._id, 'spectrum')} > <img src={see} alt="see" style={{margin:'-2px 15px 0 9px'}} /> </button>
                                                            {(item?.businessId?.spectrumApproved!=="approved"&&item?.prodAccessRequest) && <button className="view" onClick={()=>doAction(item?.businessId?._id, 'approved', 'spectrum')}>Approve</button>}
                                                            {item?.businessId?.spectrumApproved==="approved" && <button className="reload" onClick={()=>doAction(item?.businessId?._id, 'suspended', 'spectrum')} style={{marginRight: '10px'}}>Suspend</button>}
                                                            {(item?.businessId?.spectrumApproved==="pending"&&item?.prodAccessRequest) && <button className="reload" onClick={()=>doAction(item?.businessId?._id, 'declined', 'spectrum')} style={{marginRight: '10px'}}>Decline</button>}
                                                            {
                                                                item?.businessId?.spectrumApproved==="approved" ?
                                                                <img 
                                                                    data-tooltip-id="my-tooltip" 
                                                                    data-tooltip-content="This business has production access." 
                                                                    src={prodIcon} 
                                                                    alt="env" 
                                                                    style={{width: '33px'}} 
                                                                />
                                                                :
                                                                <img 
                                                                    data-tooltip-id="my-tooltip" 
                                                                    data-tooltip-content="This business has only dev access." 
                                                                    src={devIcon} 
                                                                    alt="env" 
                                                                    style={{width: '33px'}} 
                                                                />
                                                            }
                                                            <Tooltip id="my-tooltip" />
                                                        </td>
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                :
                                data.tableFor==="businesses" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                <td> #{item?._id.slice(-6)}</td>
                                                <td>
                                                    <div style={{display:'flex', alignItems:'center'}}>
                                                        <div style={{marginRight:'10px', width:'30.99px', height:'30.99px', borderRadius: '5.16418px'}}>
                                                            <img style={{width:'100%', height:'100%'}} src={item?.logo ? item?.logo : profile } alt="logo" />
                                                        </div>
                                                        
                                                        {item?.businessName}
                                                    </div>
                                                </td>
                                                <td>{item?.email}</td>
                                                <td>{item?.phoneNumber}</td>
                                                <td>{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                    <div className={item?.isActive === 'pending' || item?.isActive === 'declined' || item?.isActive === 'suspended'  ? "table-pendingBox" : 'table-approvedBox'}>
                                                    {item?.isActive === 'approved' ? 'Verified' : item?.isActive === 'pending'  ? "Pending" :  item?.isActive === 'declined' ? "Declined": item?.isActive === 'suspended' ? "Suspended" : "Verified"}
                                                    </div>
                                                    </td>
                                              
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                :
                                data.tableFor==="users" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                <td> {item?.fullname ? item?.fullname : 'Nil' }</td>
                                                <td> {item?.business[0]?.businessName ? item?.business[0]?.businessName : 'Nil'} </td>
                                                <td>{item?.bvn ? item?.bvn : "Nil"}</td>
                                                <td>{item?.email ? item?.email : item?.emails[0] === "" || item?.emails.length === 0 || item?.emails[0] === null ? 'Nil' : item?.emails[0] }</td>
                                               
                                                <td style={{textTransform:"capitalize"}}>
                                                    {item?.phone ? item?.phone : item?.phones.length === 0 || item?.phones[0] === null ? 'Nil' : item?.phones[0]}
                                                     </td>
                                                <td >{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                    {item?.gender ? item?.gender : "Nil"}
                                                    </td>
                                                <td style={{textTransform:"capitalize"}}>
                                                    {item?.maritalStatus ?item?.maritalStatus : "Nil"}
                                                    </td>
                                              
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                :
                                data.tableFor==="wallet" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                <td style={{textTransform:"capitalize"}}> #{item?.businessId?.slice(-6)}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.businessName}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                <div className={item?.type === 'Debit' ? "table-pendingBox" : 'table-approvedBox'}>
                                                    {item?.type}
                                                </div>
                                                </td>
                                                <td style={{textTransform:"capitalize"}}>{item?.debitAction ? item?.debitAction : item?.paymentSource}</td>
                                                <td>{dayjs(item?.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td className={item?.type === 'Debit' ? "table-walletDebit" : 'table-walletCredit'}>
                                                    {item?.type === 'Credit' ? formatMoney.format(item?.amountPaid) : formatMoney.format(item?.debitAmount ? item?.debitAmount : item?.amountPaid)}
                                                </td>
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                :
                                data.tableFor==="wallet-topUp" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                <td style={{textTransform:"capitalize"}}> #{item?.businessId?.slice(-6)}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.account_name}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                <div className={item?.type === 'Debit' ? "table-pendingBox" : 'table-approvedBox'}>
                                                    {item?.topUpAmount}
                                                </div>
                                                </td>
                                               
                                                <td >
                                                    { formatMoney.format(item?.previousBalance) }
                                                </td>
                                                <td >
                                                    { formatMoney.format(item?.newBalance) }
                                                </td>
                                                <td style={{textTransform:"capitalize"}}>{item?.topUpBy}</td>
                                                <td>{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                :
                                data.tableFor==="apis" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                <td style={{textTransform:"capitalize"}}> {item?.service}</td>
                                                <td >{item?.action}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.businessName}</td>
                                                <td style={{textTransform:"capitalize"}}>{formatMoney.format(item?.amount)}</td>
                                                <td style={{textTransform:"capitalize"}}>{dayjs(item?.created_at).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                <div className={item?.status === 'failed' ? "table-pendingBox" : 'table-approvedBox'}>
                                                    {item?.status=== 'success' ? 'Successful' : 'Failed' }
                                                </div>
                                                </td>
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                :
                                data.tableFor==="user-consent" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>(

                                            <tr key={idx}
                                            >
                                                 <td style={{textTransform:"capitalize"}}> #{item?._id.slice(-6)}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.borrowerId?.fullname}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.businessId?.businessName}</td>
                                                <td style={{textTransform:"capitalize"}}>{item?.action}</td>
                                                <td style={{textTransform:"capitalize"}}>
                                                <div className='table-approvedBox'>
                                                    {'Successful'}
                                                </div>
                                                </td>
                                                <td style={{textTransform:"capitalize"}}>{dayjs(item?.dateAccepted).format("DD/MM/YYYY hh:mm A")}</td>
                                               
                                            </tr>
                                         ))
                                    } 
                                </tbody>
                                  :
                                  data.tableFor==="approval-logs" ? 
                                  <tbody>
                                       {
                                          displayData?.[page]?.map((item, idx) =>(
  
                                              <tr key={idx}
                                              >
                                                  <td style={{textTransform:"capitalize"}}> {item?.adminName}</td>
                                                  <td style={{textTransform:"capitalize"}}>{item?.adminRole}</td>
                                                  <td> 
                                                    <div className={item?.action === 'declined' || item?.action === 'suspended' || item?.action === 'delete'  ? "table-pendingBox" : 'table-approvedBox'}>
                                                        {item?.action}
                                                    </div>
                                                  </td>
                                                  <td style={{textTransform:"capitalize"}}> {item?.businessName}</td>
                                                  <td style={{textTransform:"capitalize"}}> {item?.country}</td>
                                                  <td > {item?.ipAddress}</td>
                                                  <td>{dayjs(item?.logDate).format("DD/MM/YYYY hh:mm A")}</td>
                                                
                                              </tr>
                                           ))
                                      } 
                                  </tbody>
                                  :
                                  data.tableFor==="tech-support" ? 
                                  <tbody>
                                       {
                                          displayData?.[page]?.map((item, idx) =>(
                                              <tr key={idx}
                                              style={{cursor:"pointer"}}
                                              onClick={() =>doAction(item)}
                                              >
                                                  <td> {item?.record}</td>
                                                  <td style={{textTransform:"uppercase"}}>{item?.snippetMeta?.name}</td>
                                                  <td>{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                  <td> </td>
                                              </tr>
                                           ))
                                      } 
                                  </tbody>
                                  :
                                  data.tableFor==="float-breakdown" ? 
                                  <tbody>
                                       {
                                            displayData?.[page]?.map((item, idx) =>(
                                                <tr key={idx} style={{cursor:"pointer"}} >
                                                    <td>{item?.principal ? formatMoney.format(item?.principal ) : formatMoney.format(0)}</td>
                                                    <td>{item?.interest ? formatMoney.format(item?.interest ) : formatMoney.format(0)}</td>
                                                    <td>{item?.totalRepayment ? formatMoney.format(item?.totalRepayment ) : formatMoney.format(0)}</td>
                                                </tr>
                                            ))
                                      } 
                                  </tbody>
                                :
                                data.tableFor==="repay-breakdown" ? 
                                <tbody>
                                     {
                                        displayData?.[page]?.map((item, idx) =>{
                                            const sn = (idx+1)+(page*10)
                                            return (
                                                <tr key={idx} style={{cursor:"pointer"}} >
                                                    <td>{sn}{sn===1 ? 'st' : sn===2 ? 'nd' : sn===3 ? 'rd' : 'th'} payment</td>
                                                    <td>{item?.principal ? formatMoney.format(item?.principal ) : formatMoney.format(0)}</td>
                                                    <td>{item?.interest ? formatMoney.format(item?.interest ) : formatMoney.format(0)}</td>
                                                    <td>{item?.totalAmount ? formatMoney.format(item?.totalAmount ) : formatMoney.format(0)}</td>
                                                    <td>{convertDate(item?.dueDate?.substring(0, 10))}</td>
                                                    <td>{item?.repaymentStatus==="paid" ? <img src={checkmark} alt="icon" width="20px" /> : <img src={scheduledIcon} alt="icon" width="20px" /> }</td>
                                                </tr>
                                            )
                                        })
                                    } 
                                </tbody>
                              :
                                data.tableFor==="float-history" ? 
                                    <tbody>
                                        {
                                      
                                        displayData?.[page]?.map((item, idx) =>(
                                            <tr key={idx} >
                                               <td>{item._id?.slice(-8)}</td>
                                               <td>{formatMoney.format(parseInt(item?.totalAmount))}</td>
                                                <td>{dayjs(item?.lastUpdated).format("DD/MM/YYYY")}</td>
                                                <td>{dayjs(item?.lastUpdated).format("hh:mm A")}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="float-naira" ? 
                                    <tbody>
                                        {
                                        displayData?.[page]?.map((item, idx) =>
                                            item?.amountType === "naira" &&
                                        (
                                            <tr key={idx} >
                                                {!data?.floatForSales && <td>{item?.businessId?.businessName} </td>}
                                                <td>{formatMoney.format(item?.loanAmount)} </td>
                                                <td>{item?.isApproved === 'pending' || item?.isApproved === 'declined' || 
                                                        item?.isApproved === 'rejected' ? "Nil" :
                                                    formatMoney.format(item?.approvedFloat?.approvedAmount) } 
                                                 </td>
                                                <td>{item?.loanDuration} </td>
                                                {/* <td>{item?.approvedFloat?.loanTenure} months</td> */}
                                                <td>{item?.isApproved === 'pending' || item?.isApproved === 'declined' || 
                                                        item?.isApproved === 'rejected' ? "Nil" :
                                                        `${item?.approvedFloat?.loanTenure} months`}   
                                                 </td>
                                                <td>{item?.businessId?.email} </td>
                                                <td>{dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td className="table_income-btn">
                                                    <div className={item?.isApproved === 'declined' || item?.isApproved === 'rejected' || item?.isApproved === 'pending'  ? "table-pendingBox" : 'table-approvedBox'}>
                                                        {item?.isApproved}
                                                    </div>
                                                </td> 
                                                <td className="table_income-btn">
                                                    <div className="table-requestBox" onClick={()=>viewRequestInfo(item?._id)}>
                                                        View
                                                    </div>
                                                </td> 
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="float-usd" ? 
                                    <tbody>
                                        {
                                        displayData?.[page]?.map((item, idx) =>
                                            item?.amountType === "usd" &&
                                        (
                                            <tr key={idx} >
                                                {!data?.floatForSales && <td>{item?.businessId?.businessName} </td>}
                                                <td>{currencyFormatter(item?.loanAmount, "USD", 2)} </td>
                                                <td>{item?.isApproved === 'pending' || item?.isApproved === 'declined' || 
                                                        item?.isApproved === 'rejected' ? "Nil" :
                                                        currencyFormatter(item?.approvedFloat?.approvedAmount, "USD", 2) } 
                                                 </td>
                                                <td>{item?.loanDuration} </td>
                                                {/* <td>{item?.approvedFloat?.loanTenure} months</td> */}
                                                <td>{item?.isApproved === 'pending' || item?.isApproved === 'declined' || 
                                                        item?.isApproved === 'rejected' ? "Nil" :
                                                        `${item?.approvedFloat?.loanTenure} months`}   
                                                 </td>
                                                <td>{item?.businessId?.email} </td>
                                                <td>{dayjs(item?.updatedAt).format("DD/MM/YYYY hh:mm A")}</td>
                                                <td className="table_income-btn">
                                                    <div className={item?.isApproved === 'declined' || item?.isApproved === 'rejected' || item?.isApproved === 'pending'  ? "table-pendingBox" : 'table-approvedBox'}>
                                                        {item?.isApproved}
                                                    </div>
                                                </td> 
                                                <td className="table_income-btn">
                                                    <div className="table-requestBox" onClick={()=>viewRequestInfo(item?._id)}>
                                                        View
                                                    </div>
                                                </td> 
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="currency-rate" ? 
                                    <tbody>
                                        {
                                      
                                        displayData?.[page]?.map((item, idx) =>(
                                            <tr key={idx} >
                                                <td>{item?._id?.slice(-8)} </td>
                                                <td>{formatMoney.format(item?.rate)} </td>
                                                <td>{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm A")}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="float-upcoming-payment" ? 
                                    <tbody>
                                        {
                                      
                                        displayData?.[page]?.map((item, idx) =>(
                                            <tr key={idx} >
                                                <td>{item?.business?.businessName} </td>
                                                <td>{item?.business?.email} </td>
                                                <td>{formatMoney.format(item?.repaymentDetails?.totalAmount)} </td>
                                                <td>{convertDate(item?.repaymentDetails?.dueDate?.substring(0, 10))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                <div> No Data </div>
                            }
                        </table>
                           { 
                            data.tableFor !=="tech-support" &&
                                <div>
                                    <OldPagination
                                        changePage={(name, num)=>changePage(name, num)} 
                                        page={page}
                                        totalPages={displayData?.length}
                                    />  
                                </div>
                            }

                            {
                                data.tableFor ==="tech-support" && binStatus &&
                                    <div className="table_view" onClick={()=> doUpdate()}>
                                        <button> View Next</button>

                                    </div>
                            }
                    </div>
                    :
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "30vh"}}> 
                        {/* <h3>No Data </h3>  */}
                    </div>
                }    
            </div>  
            
        </div>
    )
}

export default Table;