import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Back from '../../back'
import { getBusinessSpectrumData } from "../../../lib/auth";
import { toast, ToastContainer } from 'react-toastify';
import { Tooltip } from "react-tooltip";

function Spectrum ({setDetailName}) {
    let params = useParams()
    let navigate = useNavigate();
    const [data, setData] = useState(null);

    var getInitials = function (string) {
        var names = string?.split(' '),
            initials = names?.[0]?.substring(0, 1).toUpperCase();
        return initials;
    };

    useEffect(()=>{
        getBusinessSpectrumData(params.id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setData(res.data)
                }
                else {
                    toast.error("Something went wrong, please try again!!!")
                }
            })
            .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="home_main">
            <ToastContainer />
            <div className="accounts">
                <Back to={()=> navigate("/approvals")} />
                <div className="accounts_details">
                    
                        <div className="accounts_name">
                            <div className="accounts_name-tag">
                                <p>{getInitials(`${data?.businessId?.businessName}`)}</p>
                            </div>
                            <h3>{data?.businessId?.businessName}</h3>
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Business Name</p>
                                <p className="text2">{data?.businessId?.businessName}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">RC Number</p>
                                <p className="text2">{data?.businessId?.CAC ? data?.businessId?.CAC : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Business Owner</p>
                                <p className="text2">{data?.businessId?.ownerFullName ? data?.businessId?.ownerFullName : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Business Sector</p>
                                <p className="text2">{data?.businessId?.businessSector}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">BVN</p>
                                <p className="text2">{data?.businessId?.bvn ? data?.businessId?.bvn : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">NIN</p>
                                <p className="text2">{data?.businessId?.nin ? data?.businessId?.nin : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Phone</p>
                                <p className="text2">{data?.businessId?.phoneNumber}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Email</p>
                                <p className="text2">{data?.businessId?.email}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                       

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Size</p>
                                <p className="text2">{data?.businessId?.size ? data?.businessId?.size : 'Nil' }</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Registration Type</p>
                                <p className="text2">{data?.businessId?.businessType }</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Country</p>
                                <p className="text2">{data?.businessId?.country}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">State</p>
                                <p className="text2">{data?.businessId?.state}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">LGA</p>
                                <p className="text2">{data?.businessId?.lga}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Address</p>
                                <p className="text2">{data?.businessId?.address}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                    
                        </div>

                        <div className='accounts_details-row'>
                            <div className="accounts_details-main">
                                <p className="text1">Compliance status</p>
                                <p className="text2">{data?.businessId?.isComplied === true ? 'True' : "False"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                            <div className="accounts_details-main">
                                <p className="text1">Website</p>
                                <p className="text2">{data?.businessId?.website ? data?.businessId?.website : "Nil"}</p>
                                <hr style={{color: 'blue'}}/>
                            </div>
                        </div>
                        <div className="approvals_terms">
                            <label 
                                data-tooltip-id="my-tooltip" 
                                data-tooltip-content={data?.acceptedTerms?"Spectrum terms and conditions accepted":"Spectrum terms and conditions not accepted"}
                                className="container"
                            >
                                <input 
                                    type="checkbox" 
                                    checked={data?.acceptedTerms}
                                />
                                <span className="checkmark"></span>
                                <p>Terms and Conditions</p>
                            </label>
                        </div>
                        <Tooltip id="my-tooltip" place="top" />
                </div>
            </div>
        </div>
    )
}

export default Spectrum;