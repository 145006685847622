import Status from "components/Status";
import Table from "components/Table";
import SearchField from "components/shared/SearchField";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { SalesOpsService } from "services/salesops.service";
import { currencyFormatter, handleRequestErrors } from "utils/helpers";

export default function APICalls () {
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search")
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const page = searchParams.get("page") ?? 1
    let params = useParams()

    useEffect(()=>{
        setIsLoading(true)
        SalesOpsService.getBusinessApiCalls(params?.id, {
            page,
            search
        })
            .then(response => response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setData(res?.data)
                }
            } )
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[search, page])

    return (
        <div>
            <SearchField 
                containerClass='ml-auto my-6'
                placeholder="Search by action"
            />
            <Table
                loading={isLoading}
                data={data?.results||[]}
                backendPaginated={true}
                totalPages={data?.totalPages ?? 0}
                theadClass="!font-bold py-2.5 text-[#667085] bg-[#F0F0F0] text-sm"
                tdataClass="py-6 pl-[10px] text-sm"
                trClassName="shadow-[-1px_4px_17px_-11px_rgba(0,0,0,0.25)]"
                hideTableTop={true}
                hideOverallTableBorder={true}
                hideTableBodyBorder={true}
                hideLines={true}
                paginationContainer="!px-0"
                rows={[
                    {
                        header:'Service Endpoint',
                        view: (item) => (
                            <span>
                                {item?.service||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <span>
                                {item?.action||"---"}
                            </span>
                        ),
                    },
                    {
                        header:'Total Cost',
                        view: (item) => (
                            <span>
                                {currencyFormatter(item?.amount||0)}
                            </span>
                        ),
                    },
                    {
                        header:'When',
                        view: (item) => (
                            <span>
                                {dayjs(item?.created_at).format("DD/MM/YYYY hh:mm A")}
                            </span>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <Status
                                hideDot={true}
                                text={item?.status=== 'success' ? 'Successful' : 'Failed' }
                                type={
                                    item?.status === 'failed' ? "unverified" : 'verified'
                                }
                                addClass="!text-sm py-1.5 px-4"
                            />
                        ),
                    },
                ]}
            />
        </div>
    )
} 