import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Back from '../../../../components/back'
import Box from '../../../../components/Box';
import logo from 'images/svg/profile-dummy.svg'
import SelectInput from '../../../../components/Form/SelectInput';
import Loader from '../../../../components/shared/Loader';
import Tabs from 'components/shared/Tabs';
import clsx from 'clsx';
import { handleRequestErrors } from 'utils/helpers';
import APICalls from './components/APICalls';
import BillableAPICalls from './components/BillableAPICalls';
import Borrowers from './components/Borrowers';
import WalletFunding from './components/WalletFunding';
import AccountsLinked from './components/AccountsLinked';
import FloatRequests from './components/FloatRequests';
import { SalesOpsService } from 'services/salesops.service';
import { FloatService } from 'services/float.service';


const Details = () => {
    const [query, setQuery] = useState('all')
    const [searchParams, setSearchParams] = useSearchParams();
    let params = useParams()
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isRepaymentLoading, setRepaymentLoading] = useState(false);
    const [boxData, setBoxData] = useState({
        boxFor: "sales-ops",
        item: []
    });
    const [repaymentData, setRepaymentData] = useState({})
    const [data, setData] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        SalesOpsService.getBusinessById(params?.id, query)
            .then(response => response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status === 'success'){
                    setData(res?.data)
                    setBoxData({
                        ...boxData,
                        item: res.data
                    })
                }
            } )
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(()=>{
        floatStats();

        searchParams.set('tab', "api-calls");
        setSearchParams(searchParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (name, val) => {
        if(val === 'All'){
            setQuery('all')
        }else if(val=== 'Last 7 days'){
            setQuery('last_seven_days')
        }else if(val === 'This Month'){
            setQuery('this_month')
        }else if(val === 'Last Month'){
            setQuery('last_month')
        }else if(val === 'Last 3 Months'){
            setQuery('last_three_months')
        }else if(val === 'Last 6 Months'){
            setQuery('last_six_months')
        }else if(val === 'Last 12 Months'){
            setQuery('last_twelve_months')
        }else if(val === 'Custom'){
            setQuery('custom')
        }
    }

    const floatStats = () => {
        setRepaymentLoading(true)
        FloatService.repaymentStatsByBusinessId(params?.id)
            .then(response => response.data)
            .then((res) => {
                setRepaymentLoading(false)
                if(res.status === 'success'){
                setRepaymentData(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    return (
        <div>
            <div className='w-[150px] float-right -mt-[70px]'>
                <SelectInput 
                    placeholder={"All"}
                    options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Custom"]}
                    selectChange={(item)=>handleSelectChange("Query", item)}
                />
            </div>
            <div className="box-border">
                <Back to={()=> navigate("/sales-ops")} />
                <div className='w-full bg-[#EEF3FF] rounded-[18px] my-[2em] mx-0 py-6 px-10 flex items-center justify-between'>
                {
                    isLoading ?
                    <Loader height="150px" /> :
                    <div className='flex justify-between items-center w-full'>
                    <div className='w-[160px] h-[160px] rounded-full border-[4px] border-solid border-white mr-[50px]'>
                        <img className='w-full h-full rounded-full' src={boxData?.item?.businessDetails?.logo ? boxData?.item?.businessDetails?.logo : logo} alt="icon" />
                    </div>
                    <div>
                        <h1 className='text-[30px] font-medium leading-[38px]'>{boxData?.item?.businessDetails?.businessName}</h1>
                        {
                        [
                            {
                            title: "Business ID:",
                            value: params?.id,
                            },
                            {
                            title: "Account Name:",
                            value: boxData?.item?.wallet?.[0]?.vfd_account_name||"Not available",
                            },
                            {
                            title: "Account Number:",
                            value: boxData?.item?.wallet?.[0]?.vfd_account_number||"Not available",
                            },
                            {
                            title: "VFD MFB",
                            value: "",
                            },
                        ].map((item, idx)=>(
                            <p key={idx} className='m-0 font-bold text-base leading-[24px] mb-[0.2rem]'>{item.title} {item.value}</p>
                        ))
                        }
                    </div>
                    {
                        boxData?.item?.businessDetails &&
                        <div className='ml-auto'>
                            {
                                [
                                {
                                    title: "Compliance Status",
                                    value: boxData?.item?.businessDetails?.isComplied ? 'true' : 'false',
                                    bgColor: boxData?.item?.businessDetails?.isComplied ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                {
                                    title: "App Status",
                                    value: boxData?.item?.appStatus?.[0]?.isLive ? 'true' : 'false',
                                    bgColor: boxData?.item?.appStatus?.[0]?.isLive ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                {
                                    title: "Wallet Status",
                                    value: boxData?.item?.wallet?.[0]?.vfd_released ? 'Active' : 'Inactive',
                                    bgColor: boxData?.item?.wallet?.[0]?.vfd_released ? 'bg-[#47D800]' : 'bg-[#FB2C2C]',
                                },
                                ].map((item, idx)=>(
                                <div key={idx} className='flex justify-between items-center my-2.5 mx-0'>
                                    <p className='m-0 font-bold text-base leading-[24px] mb-[0.2rem]'>{item.title}</p>
                                    <div
                                    className={clsx(
                                        "w-[65px] h-[20px] flex items-center justify-center rounded-[10px] text-white p-[5px] ml-[15px] text-sm",
                                        item.bgColor
                                    )}
                                    >
                                    {item.value}
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                    }
                    </div>
                }
                </div>
                <div className='flex items-center gap-x-8 mt-[30px] mb-[3em]'>
                    {
                        [
                            {label: "Email", value: boxData?.item?.businessDetails?.email||"---"},
                            {label: "Phone", value: boxData?.item?.businessDetails?.phoneNumber||"---"},
                            {label: "Address", value: boxData?.item?.businessDetails?.address||"---"},
                        ].map((item, idx)=>(
                            <div key={idx} className='font-medium !m-0'>
                                <p className='text-sm leading-[20px] text-[#667085]'>{item.label}</p>
                                <p className='text-base leading-[24px] text-[#344054]'>{item.value}</p>
                            </div>
                        ))
                    }
                </div>

                {
                    isLoading || isRepaymentLoading ?
                    <Loader /> :
                    <Box boxData={boxData} salesFloat={repaymentData} />
                }  
                <div className='mt-10'>
                    <div>
                        <Tabs 
                            tabs={[
                                {
                                label: "API Calls",
                                key: "api-calls"
                                },
                                {
                                label: "Billable API Calls",
                                key: "billable-api-calls"
                                },
                                {
                                label: "Borrowers Onboarded",
                                key: "borrowers"
                                },
                                {
                                label: "Wallet Funded",
                                key: "wallet-funded"
                                },
                                {
                                label: "Account Linked",
                                key: "account-linked"
                                },
                                {
                                label: "Float Requests",
                                key: "float-requests"
                                },
                            ]}
                            useAsLink={false}
                            clearSearch
                        />
                    </div>
                    {
                        searchParams.get("tab") === 'api-calls' ?  <APICalls />
                        : searchParams.get("tab") === 'billable-api-calls' ? <BillableAPICalls data={data?.billableApis} isLoading={isLoading} />
                        : searchParams.get("tab") === 'borrowers' ? <Borrowers data={data?.borrowers} isLoading={isLoading} />
                        : searchParams.get("tab") === 'wallet-funded' ? <WalletFunding data={data?.walletFunding} isLoading={isLoading} />
                        : searchParams.get("tab") === 'account-linked' ? <AccountsLinked data={data?.accountLinked} isLoading={isLoading} />
                        : searchParams.get("tab") === 'float-requests' ? <FloatRequests />
                        : null
                    }
                </div>
            </div>
        </div>
    )
}

export default Details