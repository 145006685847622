import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { floatUpcomingPayment } from '../../../lib/auth';
import { toast, ToastContainer } from 'react-toastify';
import Table from '../../Table/general.js';
// import Modal from '../../Modal';
import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import SelectInput from '../../Form/SelectInput';
import Loader from '../../shared/Loader';

const UpcomingPayment = ({setHeader}) => {
    const [query, setQuery] = useState('all')
    const [requestPath, setRequestPath] = useState("all");
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    const [show, setShow] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);

    let path = ''
    const amountType = "naira"

    try {
      path = sessionStorage.getItem('path')
    } catch (error) {}

    useEffect(() => {
      setHeader();
      setRequestPath(path ? path : 'all')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);

const handleClose = () => setShow(false);

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

  const [data, setData] = useState({
    tableFor: "float-upcoming-payment",
    headings: ["Business Name", "Email", "Total Amount", "Due Date"],
    items: []
  });

  const filterByDate = (data) => {
   const result = data.sort(function(a, b) {
      return new Date(a?.repaymentDetails?.dueDate) - new Date(b?.repaymentDetails?.dueDate);
    });

    return result
  }
  

useEffect(()=>{
  setIsTableLoading(true)
  floatUpcomingPayment(query, amountType)
  .then((response) => response.data)
  .then((res) => {
    if (res.status === 'success') {
        const data = filterByDate(res.data)
        console.log(data)
        setFilteredData(data)
        setIsTableLoading(false)
    } else {
        toast.error("Something went wrong, please try again!!!")
        setIsTableLoading(false)
    }
  })
  .catch((err) => {
    if(err.response){
        toast.error(err.response.data.message);
        setIsTableLoading(false)
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
        setIsTableLoading(false)
    }
  });
// eslint-disable-next-line react-hooks/exhaustive-deps
},[requestPath, query])

useEffect(() => {
  const filtered = filteredData?.filter((item) => 
  item?.business?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
  item?.business?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) 
  )
  setData({
    ...data,
    items: filtered
  })
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchTerm, filteredData])

const handleSelectChange = (name, val) => {
  if
  (val === 'All'){
    setQuery('all')
  }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
    // setQueryVal('Last 7 days')
  }else if(val === 'This Month'){
    setQuery('this_month')
    // setQueryVal('This Month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
    // setQueryVal('Last Month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
    // setQueryVal('Last 3 Months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
    // setQueryVal('Last 6 Months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
    // setQueryVal('Last 12 Months')
  }else if(val === 'Custom'){
    setQuery('custom')
    // setQueryVal('Custom')
    setShow(true)
  }
}

    const handleChange = (e) => {
      setSearchTerm(e.target.value)
    }

    return (
      <div className='home_main'>
        <ToastContainer />

        <Modal show={show} onHide={handleClose}>
          <DateRange
              editableDateInputs={true}
              onChange={item => {
                  setStartDateee(formatPickerDate(item.selection.startDate))
                  setEndDateee(formatPickerDate(item.selection.endDate))
                  setState([item.selection])
                }
              }
              moveRangeOnFirstSelection={false}
              ranges={state}
          />
          <button 
              style={{
                  color:"white",
                  padding:"7px 25px",
                  textAlign:"center",
                  border:"1px solid #0046E6",
                  background:"#0046E6"
              }} 
              onClick={()=>{   
                      handleClose();
                      setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                  }}
              >Proceed</button>
        </Modal>

        <div className='w-[150px] float-right -mt-[70px]'>
          
          <SelectInput 
              placeholder={"All"}
              options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months",  "Custom"]}
              selectChange={(item)=>handleSelectChange("Query", item)}
          />
        </div>

        <div className='home_main-tableHeader' >
          <div className="table_top" style={{display:"flex", alignItems:"center"}}>
              <div>
                <h4>Upcoming Payments</h4>
              </div>
              <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                  <input placeholder="Search float" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                  />
                  <img src={search} alt="search" />
              </div>
          </div>
        </div>
  
          {
            isTableLoading ?
            <Loader /> :
            <Table 
              data={data} 
            />
          }

      
      </div>
    )
  }

export default UpcomingPayment