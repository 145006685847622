import { APIKit, loadToken } from "services";

const timeout = 15000;


export const BusinessService = {
    getTopBusinesses: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-activity?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    revenueActivity: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/revenue-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    customerOnboarding: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/business-graph`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    apiActivity: () => {
        return APIKit(timeout).get(`eagleeyeauth/business/api-activity`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getBusinessData: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/stats/get-business-data?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getAccountData: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/get-wallet-data?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getApiData: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/business/get-api-data?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },

    getRevenueData: (type) => {
        return APIKit(90000).get(`eagleeyeauth/business/get-revenue-data?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    },
}