import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { businessStats, getBusinessByStatus } from '../../../lib/auth';
import SelectInput from '../../../components/Form/SelectInput';
import Table from '../../../components/Table';
import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import filter_lines from '../../../images/svg/filter_lines.svg';
import check from '../../../images/svg/check.svg';
import { dateFormatter } from 'utils/helpers';
import Status from 'components/Status';
import { mkConfig, generateCsv, download } from "export-to-csv";
import DataBox from 'components/shared/DataBox';
import TopBusinesses from '../Overview/components/TopBusinesses';
import clsx from 'clsx';
import walletBlue from 'images/svg/box/walletBlue.svg'
import walletGreen from 'images/svg/box/walletGreen.svg'
import walletAsh from 'images/svg/box/walletAsh.svg'
import graph from 'images/svg/box/graph.svg'
import graphRed from 'images/svg/box/graphRed.svg'
import user from 'images/svg/box/user.svg'



const Businesses = ({setDetailName}) => {
  const [query, setQuery] = useState('this_month')
  const [queryVal, setQueryVal] = useState('This month')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [startDateee, setStartDateee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDatee] = useState("");
  const [show, setShow] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState({
    showOptions: false,
    selected: "all"
  })
  const [CSVItems, setCSVItems] = useState([]);

  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


useEffect(() => {
  setDetailName("");
}, [setDetailName]);

const handleClose = () => setShow(false);

  const [boxData, setBoxData] = useState({
    boxFor: "businesses",
    item: []
  });

  const [tableData, setTableData] = useState({
      tableFor: "businesses",
      headings: ["ID", "Business Name", "Email", "Phone", "Date", "Status"],
      items: []
  });


  function formatPickerDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }


  useEffect(() => {
    businessStats(query)
    .then(response => response.data)
    .then((res) => {
      if(res.status === 'success'){
        setBoxData({
          ...boxData,
          item: res.data
        })
      } 
    })
    .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    setCustomStartDate(`${new Date(startDateee)?.getDate()} ${months[new Date(startDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    setCustomEndDatee(`${new Date(endDateee)?.getDate()} ${months[new Date(endDateee)?.getMonth()]?.substring(0,3)} ${new Date(endDateee)?.getFullYear()}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateee, endDateee, ])

  useEffect(() => {
    setIsTableLoading(true)
    getBusinessByStatus(filterStatus.selected==="all"?"":filterStatus.selected)
    .then(response => response.data)
    .then((res) => {
      setIsTableLoading(false)
      if(res.status === 'success'){
        setFilteredData(res?.data);
      } else {
        toast.error("Something went wrong, please try again!!!");
      }
  })
  .catch((err) => {
    setIsTableLoading(false)
    if(err.response){
        toast.error(err.response.data.message);
    } else{
        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    }
  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus.selected])

  useEffect(() => {
    const filtered = filteredData?.filter((item) => 
      item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
      item?.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
      item?.phoneNumber?.toLowerCase()?.includes(searchTerm.toLowerCase())
    ) 
    setTableData({
      ...tableData,
      items: filtered
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, filteredData])

  const handleSelectChange = (name, val) => {
    if
    (val=== 'Last 7 days'){
      setQuery('last_seven_days')
      setQueryVal('Last 7 days')
    }else if(val === 'This Month'){
      setQuery('this_month')
      setQueryVal('This Month')
    }else if(val === 'Last Month'){
      setQuery('last_month')
      setQueryVal('Last Month')
    }else if(val === 'Last 3 Months'){
      setQuery('last_three_months')
      setQueryVal('Last 3 Months')
    }else if(val === 'Last 6 Months'){
      setQuery('last_six_months')
      setQueryVal('Last 6 Months')
    }else if(val === 'Last 12 Months'){
      setQuery('last_twelve_months')
      setQueryVal('Last 12 Months')
    }else if(val === 'Custom'){
      setQuery('custom')
      setQueryVal('Custom')
      setShow(true)
    }
  }

  const handleChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const csvConfig = mkConfig({ 
    useKeysAsHeaders: true,
    filename: "businesses"
  });

  return (
    <div className='mt-10 mb-[60px]'>
      <Modal show={show} onHide={handleClose}>
        <DateRange
            editableDateInputs={true}
            onChange={item => {
                    setStartDateee(formatPickerDate(item.selection.startDate))
                    setEndDateee(formatPickerDate(item.selection.endDate))
                    setState([item.selection])
                }
            }
            moveRangeOnFirstSelection={false}
            ranges={state}
        />
        <button 
            style={{
                color:"white",
                padding:"7px 25px",
                textAlign:"center",
                border:"1px solid #0046E6",
                background:"#0046E6"
            }} 
            onClick={()=>{   
                    handleClose();
                    setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                    setQueryVal(`${customStartDate} - ${customEndDate}`)
                }}
            >Proceed</button>
      </Modal>
      <div className='w-[150px] float-right -mt-[70px]'>
        <SelectInput 
          placeholder={"This Month"}
          options={["Last 7 days", "This Month", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months",  "Custom"]}
          selectChange={(item)=>handleSelectChange("Query", item)}
        />
      </div>
      <div className='grid grid-cols-2 gap-x-10 box-border mb-10'>
        <div>
          <div className='grid grid-cols-2 gap-[20px] box-border'>
            {
              [
                {
                  title: "New Businesses",
                  value: boxData?.item?.newBusinesses || 0,
                  icon: walletBlue,
                  percentageDifference: boxData?.item?.businessPercent,
                },
                {
                  title: "Approved Businesses",
                  value: boxData?.item?.approvedBusinesses||0,
                  icon: walletGreen,
                },
                {
                  title: "Inactive Businesses",
                  value: boxData?.item?.inactiveBusinesses||0,
                  icon: walletAsh,
                },
                {
                  title: "Total Businesses",
                  value: boxData?.item?.totalBusinesses||0,
                  icon: walletBlue,
                },
                {
                  title: "Active Businesses",
                  value: boxData?.item?.activeBusinesses||0,
                  icon: walletGreen,
                  percentageDifference: boxData?.item?.activePercent ,
                },
                {
                  title: "Business Team Users",
                  value: boxData?.item?.totalUsers||0,
                  icon: user,
                  percentageDifference: boxData?.item?.userPercent,
                },
              ].map(item => (
                <div key={item.title}>
                  <DataBox 
                    title={item.title}
                    icon={<img src={item.icon} alt={item.title} className='w-[20px]' />}
                    value={item.value}
                    className="!shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]"
                    slot={
                      !!item?.percentageDifference ?
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-x-0.5'>
                          <img src={item?.percentageDifference < 0 ? graphRed : graph} alt="icon" /> 
                          <span 
                            className={clsx(
                              "text-sm",
                              item?.percentageDifference < 0 ? 'text-[#FA582E]':"text-[#4BDE97]"
                            )}
                          >
                            {Math.abs(item?.percentageDifference||0)}
                          </span>
                        </div>
                        <p className='text-sm text-[#B9B9B9]'>{queryVal}</p>
                      </div>
                      : null
                    }
                  />
                </div>
              ))
            }
          </div>
        </div>
        <div className='bg-white rounded-[10px] p-[15px] shadow-[0px_13.3657px_44.5523px_rgba(160,163,189,0.5)]'>
          <TopBusinesses query={query} height='h-[330px]' />
        </div>
      </div>
      <Table
          tableTop={
            <div className='my-1.5'>
              <div className=" flex items-center">
                  <div>
                    <h4>Business Info</h4>
                  </div>
                  <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                      <input placeholder="Search businesses" onChange={handleChange} className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                      />
                      <img src={search} alt="search" />
                  </div>
                  <div className='border border-[#D0D5DD] rounded-[8px] p-2 ml-3 cursor-pointer relative'>
                    <div className='flex gap-x-2' onClick={()=>setFilterStatus({...filterStatus, showOptions:!filterStatus.showOptions})}>
                      <img src={filter_lines} alt="" />
                      <p className='text-[#344054] text-sm font-medium capitalize min-w-[80px]'>{filterStatus.selected==="all" ? "Select Status":filterStatus.selected}</p>
                    </div>
                    {
                      filterStatus.showOptions &&
                      <div className='absolute w-full top-[40px] z-20 left-0 bg-transparent h-10'>
                        <div 
                          className='flex justify-between items-center bg-[#F2F4F7] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "all"})}
                        >
                          <p className='text-sm'>All</p>
                          { filterStatus.selected==="all" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F9FAFB] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "active"})}
                        >
                          <p className='text-sm'>Active</p>
                          { filterStatus.selected==="active" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F2F4F7] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "inactive"})}
                        >
                          <p className='text-sm'>Inactive</p>
                          { filterStatus.selected==="inactive" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F9FAFB] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "approved"})}
                        >
                          <p className='text-sm'>Approved</p>
                          { filterStatus.selected==="approved" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F2F4F7] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "pending"})}
                        >
                          <p className='text-sm'>Pending</p>
                          { filterStatus.selected==="pending" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F9FAFB] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "suspended"})}
                        >
                          <p className='text-sm'>Suspended</p>
                          { filterStatus.selected==="suspended" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                        <div 
                          className='flex justify-between items-center bg-[#F2F4F7] px-2.5 py-2 mb-0.5'
                          onClick={()=>setFilterStatus({...filterStatus, showOptions: false, selected: "declined"})}
                        >
                          <p className='text-sm'>Declined</p>
                          { filterStatus.selected==="declined" && <img src={check} alt="mark" className='w-4' /> }
                        </div>
                      </div>
                    }
                  </div>
              </div>
            </div>
          }
          loading={isTableLoading}
          showDownload={true}
          onDownloadClick={()=>{
            if(CSVItems.length>0){
              const csv = generateCsv(csvConfig)(CSVItems);
              download(csvConfig)(csv)
            }
          }}
          data={tableData.items}
          tableShadow={true}
          hideLines={true}
          theadClass="font-normal text-[#06152B] pl-[20px] py-3"
          tdataClass="py-3 pl-[20px] text-sm text-[#06152B]"
          rows={[
            {
                header: <span>
                  <input 
                    type="checkbox" 
                    checked={tableData?.items?.length===CSVItems?.length}
                    className='scale-150 mt-2' 
                    onChange={()=>{
                      if(tableData?.items?.length===CSVItems?.length){
                        setCSVItems([])
                      } else {
                        setCSVItems(tableData.items)
                      }
                    }}
                  />
                </span>,
                view: (item) => (
                    <span>
                        <input 
                          type="checkbox" 
                          checked={CSVItems?.includes(item)}
                          className='scale-150 mt-2' 
                          onChange={()=>{
                            if(!CSVItems.includes(item)){
                              setCSVItems([...CSVItems, item])
                            } else {
                              const newArray = CSVItems.filter(x => x !== item)
                              setCSVItems(newArray)
                            }
                          }}
                        />
                    </span>
                ),
            },
              {
                  header:'ID',
                  view: (item) => (
                      <span>
                          #{item?._id.slice(-6)}
                      </span>
                  ),
              },
              {
                  header:'Business Name',
                  view: (item) => (
                    <div className='max-w-[200px]'>
                      <span>
                          {item?.businessName}
                      </span>
                    </div>
                  ),
              },
              {
                  header:'Email',
                  view: (item) => (
                      <span className="lowercase">
                          {item?.email}
                      </span>
                  ),
              },
              {
                  header:'Phone',
                  view: (item) => (
                      <span>
                          {item?.phoneNumber}
                      </span>
                  ),
              },
              {
                  header:'Date',
                  view: (item) => (
                      <span>
                        {dateFormatter(item.createdAt, true)}
                      </span>
                  ),
              },
              {
                  header:'Status',
                  view: (item) => (
                      <span>
                          <Status
                              type={
                                  item.isActive==="pending"?"pending"
                                  : item.isActive==="declined"?"fail"
                                  : item.isActive==="suspended"?"pending"
                                  : "success"
                              }
                              text={item?.isActive === 'approved' ? 'Verified' : item?.isActive}
                              hideDot={true}
                              addClass="text-sm px-4 py-1"
                          />
                      </span>
                  ),
              },
          ]}
      />
    </div>
  )
}

export default Businesses