import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import { devIncomeActivity, getTotalFunded, prodIncomeActivity, 
  walletStats, walletTransaction } from '../../../lib/auth';
import Box from '../../Box';
import SelectInput from '../../Form/SelectInput';
import Table from '../../Table/general.js';
import LineChartSuper from '../Chart/LineChart';
import search from '../../../images/svg/search.svg'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import 'react-date-range/dist/styles.css';
import { CSVLink } from 'react-csv';
import download from '../../../images/svg/download.svg';
import Loader from '../../shared/Loader';

const Wallets = ({setDetailName}) => {
  const [query, setQuery] = useState('all')
  const [queryVal, setQueryVal] = useState('This month')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [walletFunded, setWalletFunded] = useState('')
  const [devData, setDevData] = useState([])
  const [prodData, setProdData] = useState([])
  const [startDateee, setStartDateee] = useState("");
  const [endDateee, setEndDateee] = useState("");
  const [show, setShow] = useState(false);
  const [requestPath, setRequestPath] = useState("all");
  const [isBoxLoading, setIsBoxLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isFundingLoading, setIsFundingLoading] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
]);


useEffect(() => {
  setDetailName("");
}, [setDetailName]);

const handleClose = () => setShow(false);

  const [boxData, setBoxData] = useState({
    boxFor: "wallet",
    item: []
  });

  const [businessGraphData, setBusinessGraphData] = useState({
    graphFor: "Amount_Funded",
    item: []
  });

  const [tableData, setTableData] = useState({
    tableFor: "wallet",
    headings: ["ID", "Business Name", "Type", "Action", "Date", "Amount"],
    items: []
});

var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

var today = new Date();
var d;
var month = [];
// let monthSlice = [];

for(var i = 12; i > 0; i -= 1) {
  d = new Date(today.getFullYear(), today.getMonth() - i, 1);
  month.push(monthNames[d.getMonth()]);
}

useEffect(() => {
  setIsBoxLoading(true)

  walletStats(query)
  .then(response => response.data)
  .then((res) => {
    if(res.status === 'success'){
      setBoxData({
        ...boxData,
        item: res.data
      })
      setIsBoxLoading(false)
    } else {
      toast.error("Something went wrong, please try again!!!")
      setIsBoxLoading(false)
  }
})
.catch((err) => {
  if(err.response){
      toast.error(err.response.data.message);
      setIsBoxLoading(false)
  } else{
      toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      setIsBoxLoading(false)
  }
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [query])

useEffect(() => {
  setIsTableLoading(true)
  walletTransaction(query, requestPath)
  .then(response => response.data)
  .then((res) => {
    if(res.status === 'success'){
      setFilteredData(res.data)
      setIsTableLoading(false)
    } else {
      toast.error("Something went wrong, please try again!!!")
      setIsTableLoading(false)
  }
})
.catch((err) => {
  if(err.response){
      toast.error(err.response.data.message);
      setIsTableLoading(false)
  } else{
      toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
      setIsTableLoading(false)
  }
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [query, requestPath])

useEffect(() => {

  const filtered = filteredData?.filter((item) => 
  item?.businessName?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
  item?.type?.toLowerCase()?.includes(searchTerm.toLowerCase()) || 
  item?.debitAction?.toLowerCase()?.includes(searchTerm.toLowerCase())
  ) 

  setTableData({
    ...tableData,
    items: filtered
  })
   // eslint-disable-next-line react-hooks/exhaustive-deps
}, [searchTerm, filteredData])

useEffect(() => {
  setIsFundingLoading(true)
  getTotalFunded(query)
  .then(response => response.data)
  .then((res) => {
    if(res.success === true){
      setWalletFunded(res.data)
      setIsFundingLoading(false)
    }  else {
      toast.error("Something went wrong, please try again!!!")
      setIsFundingLoading(false)
  }
})
.catch((err) => {
  if(err.response){
    toast.error(err.response.data.message);
    setIsFundingLoading(false)
  } else{
    toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
    setIsFundingLoading(false)
  }
});
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [query])

useEffect(()=>{
  
  prodIncomeActivity(query)
    .then((response) => setProdData(response.data.data))
  devIncomeActivity(query)
    .then((response) => response.data)
    .then((res) => {
        if (res.success === true ) {
          setDevData(res.data)
        } else {
            toast.error("Something went wrong, please try again!!!")
        }
    })
    .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

useEffect(() => {
    setBusinessGraphData( {
      ...businessGraphData,
      item:[
        {
          name: month[0].substring(0, 3),
          Amount_Funded: prodData?.[12],
        },
        {
          name: month[1].substring(0, 3),
          Amount_Funded: prodData?.[11],
        },
        {
          name: month[2].substring(0, 3),
          Amount_Funded: prodData?.[10],
        },
        {
          name: month[3].substring(0, 3),
          Amount_Funded: prodData?.[9],
        },
        {
          name: month[4].substring(0, 3),
          Amount_Funded: prodData?.[8],
        },
        {
          name: month[5].substring(0, 3),
          Amount_Funded: prodData?.[7],
        },
        {
          name: month[6].substring(0, 3),
          Amount_Funded: prodData?.[6],
        },
        {
          name: month[7].substring(0, 3),
          Amount_Funded: prodData?.[5],
        },
        {
          name: month[8].substring(0, 3),
          Amount_Funded: devData?.[4] + prodData?.[4],
        },
        {
          name: month[9].substring(0, 3),
          Amount_Funded: devData?.[3] + prodData?.[3],
        },
        {
          name: month[10].substring(0, 3),
          Amount_Funded: devData?.[2] + prodData?.[2],
        },
        {
          name: month[11].substring(0, 3),
          Amount_Funded: devData?.[1] + prodData?.[1],
        },
      ]
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [devData, prodData])

const handleChange = (e) => {
  setSearchTerm(e.target.value)
}

function formatPickerDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const handleSelectChange = (name, val) => {
  if
  (val === 'All'){
    setQuery('all')
  }else if
  (val=== 'Last 7 days'){
    setQuery('last_seven_days')
    setQueryVal('Last 7 days')
  }else if(val === 'This Month'){
    setQuery('this_month')
    setQueryVal('This Month')
  }else if(val === 'Last Month'){
    setQuery('last_month')
    setQueryVal('Last Month')
  }else if(val === 'Last 3 Months'){
    setQuery('last_three_months')
    setQueryVal('Last 3 Months')
  }else if(val === 'Last 6 Months'){
    setQuery('last_six_months')
    setQueryVal('Last 6 Months')
  }else if(val === 'Last 12 Months'){
    setQuery('last_twelve_months')
    setQueryVal('Last 12 Months')
  }else if(val === 'Custom'){
    setQuery('custom')
    setQueryVal('Custom')
    setShow(true)
  }
}
    
  return (
  
      <div className='home_main'>

          <ToastContainer />

          <Modal show={show} onHide={handleClose}>
              <DateRange
                  editableDateInputs={true}
                  onChange={item => {
                          setStartDateee(formatPickerDate(item.selection.startDate))
                          setEndDateee(formatPickerDate(item.selection.endDate))
                          setState([item.selection])
                      }
                  }
                  moveRangeOnFirstSelection={false}
                  ranges={state}
              />
              <button 
                  style={{
                      color:"white",
                      padding:"7px 25px",
                      textAlign:"center",
                      border:"1px solid #0046E6",
                      background:"#0046E6"
                  }} 
                  onClick={()=>{   
                          handleClose();
                          setQuery(`custom&start=${startDateee}&end=${endDateee}`)
                      }}
                  >Proceed</button>
          </Modal>
          <div className='w-[150px] float-right -mt-[70px]'>
            
            <SelectInput 
                placeholder={"All"}
                options={["All", "Last 7 days", "This Month", "Last Month", "Last 3 Months",  "Last 6 Months", "Last 12 Months", "Custom"]}
                selectChange={(item)=>handleSelectChange("Query", item)}
            />

          </div>
        <div>
          <div style={{flex: 1, marginRight: '40px'}}>
            {
              isBoxLoading ?
              <Loader /> :
              <Box boxData={boxData} walletFunded={walletFunded} query={queryVal} />
            }
          </div>

          <div className='home_main-overview-activity' >
              <h3> Income </h3>
              <p> Total Amount Funded </p>
              {
                isFundingLoading ?
                <Loader /> :
                <LineChartSuper data={businessGraphData} />
              }
             
            </div>

        </div>

        <div className='home_main-tableHeader' >
          <div className="table_top" style={{display:"flex", alignItems:"center"}}>
              <div>
                <h4>Transaction Activities</h4>
              </div>
              <div className="ml-auto rounded-[10px] flex w-[230px] h-10 p-[0.5em] items-center bg-white border-[1px] border-solid border-[#D0D5DD] box-border">
                  <input placeholder="Search wallets" onChange={handleChange}  className='flex-1 bg-transparent border-0 outline-0 pl-[0.5em] font-normal leading-[16px] text-[#06152B] opacity-50 text-[12.64px]'
                  />
                  <img src={search} alt="search" />
              </div>
          </div>
        </div>

        <div className="settings_navMain">
  
          <p className={requestPath==="all"?"active":""} 
              onClick={()=> {
                setRequestPath("all")
              }}
          >All Transactions</p>
          <p className={requestPath==="credit"?"active":""} 
              onClick={()=> {
                setRequestPath("credit")

              }}
          >Credit Transactions</p>
          <p className={requestPath==="debit"?"active":""} 
              onClick={()=> { 
                setRequestPath("debit")
              }}
          >Debit Transactions</p>
        </div> 
        {
          isTableLoading ?
            <Loader /> :
            <Table  
              data={tableData} 
            />
        }
        <div className='home_main-csv' >
          { 
            tableData.items.length > 0 &&
            <CSVLink data={tableData.items} filename={"wallet-transactions.csv"} >
              <img src={download} alt="download" />CSV
            </CSVLink>
          }
        </div>
     </div>
  )
}

export default Wallets