import { APIKit, loadToken } from "services";

const timeout = 15000;

let walletToken = process.env.REACT_APP_WALLET_TOKEN

export const WalletService = {
    getTotalFunded: (type) => {
        return APIKit(timeout).get(`/wallet/stats?type=${type}`, {
            headers: {
                token: walletToken
            }
        })
    },

    prodIncomeActivity: (type) => {
        return APIKit(timeout).get(`eagleeyeauth/wallet/income-graph?type=${type}`, {
            headers: {
                Authorization: `Bearer ${loadToken()}`
            }
        })
    }
}